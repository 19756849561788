import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import { addWeeks, endOfToday, isAfter, isBefore } from 'date-fns'
import { useEffect } from 'react'
import Purchases, { CustomerInfo } from 'react-native-purchases'
import MedicalInfoType from '../enums/medicalInfoType'
import Species from '../enums/species'
import { useAppStore } from '../store/useAppStore'
import trpc from '../utils/trpc'

const OrganizationService = {
  useCurrentOrganization: () => {
    const currentOrganization =
      useAppStore.use.currentOrganization().organization
    const setCurrentOrganization = useAppStore.use.setCurrentOrganization()

    const queryClient = useQueryClient()

    const organizationQuery = trpc.organization.byId.useQuery(
      {
        organizationId: currentOrganization?.id || '',
      },
      {
        enabled: !!currentOrganization,
      }
    )

    const currentUserQuery = trpc.user.current.useQuery({
      organizationId: currentOrganization?.id,
    })

    const organizations = currentUserQuery.data?.organizations.map(
      (org) => org.organization
    )

    if (organizations?.length && !currentOrganization) {
      setCurrentOrganization({
        organization: {
          id: organizations?.[0].id,
          adoptionContract: organizations?.[0].adoptionContract || null,
          logo: organizations?.[0].logo || null,
          qrCode: organizations?.[0].qrCode || null,
          name: organizations?.[0].name,
        },
      })
    }

    useEffect(() => {
      if (currentOrganization && organizationQuery.data) {
        currentOrganization.id = organizationQuery.data.id
        currentOrganization.name = organizationQuery.data.name
        currentOrganization.logo = organizationQuery.data.logo
        currentOrganization.qrCode = organizationQuery.data.qrCode
        currentOrganization.adoptionContract =
          organizationQuery.data.adoptionContract
        setCurrentOrganization({ organization: currentOrganization })
      } else if (organizationQuery.data) {
        setCurrentOrganization({ organization: organizationQuery.data })
      }
    }, [currentOrganization, organizationQuery.data, setCurrentOrganization])

    useEffect(() => {
      if (!currentOrganization?.id) {
        return
      }

      const listener = async (customerInfo: CustomerInfo) => {
        currentOrganization.subscriptionActive =
          !!customerInfo?.entitlements.all.premium.isActive

        setCurrentOrganization({
          organization: currentOrganization,
        })
      }

      // no-op if there are no listeners already
      Purchases.removeCustomerInfoUpdateListener(listener)

      Purchases.addCustomerInfoUpdateListener(listener)

      return () => {
        Purchases.removeCustomerInfoUpdateListener(listener)
      }
    }, [currentOrganization, currentOrganization?.id, setCurrentOrganization])

    return {
      currentOrganization,
      refreshOrganization: () => {
        queryClient.invalidateQueries(getQueryKey(trpc.organization.byId))
        queryClient.invalidateQueries(getQueryKey(trpc.user.current))

        return organizationQuery.refetch()
      },
      organizationQueryData: organizationQuery.data,
    }
  },
  useIsSaving() {
    const mutationKey = getQueryKey(trpc.organization.update)
    return useIsMutating({ mutationKey }) > 0
  },
  useMedicalInfo: (
    type: MedicalInfoType,
    birthDate?: Date | null,
    species?: Species
  ) => {
    const currentOrganization =
      useAppStore.use.currentOrganization().organization

    const organization = trpc.organization.byId.useQuery(
      {
        organizationId: currentOrganization?.id || '',
      },
      {
        enabled: !!currentOrganization,
      }
    ).data

    if (!organization?.medicalInfos) {
      return []
    }

    const filteredMedicalInfos = organization.medicalInfos
      .filter(
        (m) =>
          m.type === type &&
          (!m.species || m.species === species) &&
          !!birthDate &&
          (!m.minAgeWeeks ||
            isAfter(endOfToday(), addWeeks(birthDate, m.minAgeWeeks || 0))) &&
          (!m.maxAgeWeeks ||
            isBefore(endOfToday(), addWeeks(birthDate, m.maxAgeWeeks || 0)))
      )
      .sort((a, b) => {
        if (a.minAgeWeeks && b.minAgeWeeks) {
          return a.minAgeWeeks - b.minAgeWeeks
        }

        if (a.minAgeWeeks && !b.minAgeWeeks) {
          return -1
        }

        if (!a.minAgeWeeks && b.minAgeWeeks) {
          return 1
        }

        return 0
      })

    return filteredMedicalInfos
  },
}

export default OrganizationService
