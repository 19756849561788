import { router } from 'expo-router'
import { Platform, View } from 'react-native'
import { IconButton, Switch, Text } from 'react-native-paper'
import { useAppStore } from '../../../store/useAppStore'

const UserListHeaderContent = () => {
  const includeDeactivated = useAppStore.use.includeDeactivated()
  const setIncludeDeactivated = useAppStore.use.setIncludeDeactivated()

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
      }}
    >
      <Text id="includeDeactivatedLabel">Include Deactivated</Text>
      <Switch
        aria-labelledby="includeDeactivatedLabel"
        onValueChange={() => setIncludeDeactivated(!includeDeactivated)}
        value={includeDeactivated}
      />
      {Platform.OS !== 'web' && (
        <IconButton
          icon="magnify"
          onPress={() => {
            router.push(`/search`)
          }}
          testID="search-icon-button"
        />
      )}
    </View>
  )
}

export default UserListHeaderContent
