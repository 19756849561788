import * as Linking from 'expo-linking'
import { useLocalSearchParams, useRouter } from 'expo-router'
import { useCallback, useEffect, useState } from 'react'
import { Platform, View } from 'react-native'
import { ActivityIndicator, Text } from 'react-native-paper'
import Snackbar from '../../components/snackbar/Snackbar'
import { useAppStore } from '../../store/useAppStore'
import {
  clearLoginAttemptInfo,
  consumeLinkCode,
  doesSessionExist,
} from '../../utils/auth/auth'
import log from '../../utils/datadog/log/log'

const Verify = () => {
  const router = useRouter()
  const setAuth = useAppStore.use.setAuth()

  const [preAuthSessionId, setPreAuthSessionId] = useState<string | undefined>()
  const [linkCode, setLinkCode] = useState<string | undefined>()

  // This doesn't work on web because the hash is not included in the URL
  const routeParams = useLocalSearchParams<{
    rid: string
    preAuthSessionId: string
    tenantId: string
    '#': string
  }>()

  // So we fetch the url here on web, and fetch the hash value ourselves
  if (Platform.OS === 'web') {
    Linking.getInitialURL().then((url) => {
      if (url) {
        const hashIndex = url.indexOf('#')
        if (hashIndex > -1) {
          const hash = url.substring(hashIndex + 1)
          setLinkCode(hash)
        }
      }
    })
  }

  const handleMagicLinkClicked = useCallback(
    async (code: string, sessionId: string) => {
      try {
        const response = await consumeLinkCode(code, sessionId)

        if (response.status === 'OK') {
          await clearLoginAttemptInfo()
          if (
            response.createdNewRecipeUser &&
            response.user.loginMethods.length === 1
          ) {
            // user sign up success
          } else {
            // user sign in success
          }

          setAuth({
            accessToken: undefined,
            isLoggedIn: true,
          })
          await clearLoginAttemptInfo()
          router.navigate('/')
        } else if (response.status === 'EXPIRED_USER_INPUT_CODE_ERROR') {
          log.error('auth failed with EXPIRED_USER_INPUT_CODE_ERROR', response)

          Snackbar.error('The magic link has expired. Please try again.')

          await clearLoginAttemptInfo()

          router.navigate('/login')
        } else {
          log.error(`auth failed with ${response.status}`, response)

          Snackbar.error(
            'There was a problem with your magic link. Please try again.'
          )

          await clearLoginAttemptInfo()

          router.navigate('/login')
        }
      } catch (err: any) {
        log.error('auth failed', err)
        if (err.isSuperTokensGeneralError === true) {
          log.error(
            'something happened while verifying the magic link',
            err.message
          )

          Snackbar.error(
            'There was a problem with your magic link. Please try again.'
          )
        } else {
          Snackbar.error(
            'There was a problem with your magic link. Please try again.'
          )
        }
        router.navigate('/login')
      }
    },
    [router, setAuth]
  )

  useEffect(() => {
    // A user who is currently logged in may inadvertently click on a magic link
    // If this happens we want to redirect them to the home page rather than try to use the link
    doesSessionExist().then((exists) => {
      if (exists) {
        router.navigate('/')
        return
      }
    })

    const sessionId = Array.isArray(routeParams.preAuthSessionId)
      ? routeParams.preAuthSessionId[0]
      : routeParams.preAuthSessionId

    if (sessionId) {
      setPreAuthSessionId(sessionId)
    }

    const code = Array.isArray(routeParams['#'])
      ? routeParams['#'][0]
      : routeParams['#']

    if (code) {
      setLinkCode(code)
    }

    if (!preAuthSessionId && !preAuthSessionId) {
      log.error('preAuthSessionId or linkCode not found in query params')
      return
    }

    handleMagicLinkClicked(linkCode || '', preAuthSessionId)
  }, [
    handleMagicLinkClicked,
    linkCode,
    preAuthSessionId,
    routeParams,
    router,
    setAuth,
  ])

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ActivityIndicator animating={true} size="large" />
      <Text style={{ marginTop: 10 }}>
        Validating your login credentials...
      </Text>
    </View>
  )
}

export default Verify
