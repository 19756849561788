import { Control, FieldErrors, FieldValues, Path } from 'react-hook-form'
import { DropdownProps } from 'react-native-element-dropdown/lib/typescript/components/Dropdown/model'
import Gender from '../../../../enums/gender'
import FormFieldSelect from '../../../form/FormFieldSelect'

type data = { label: string; value: string }

interface Props<C extends FieldValues, E extends FieldErrors> {
  control: Control<C, any>
  disabled?: boolean
  errors?: E
  fieldName?: Path<C>
  isLoading?: boolean
  submit?: () => void
}

const FormFieldGender = <C extends FieldValues, E extends FieldErrors>({
  control,
  disabled,
  errors,
  fieldName = 'gender' as Path<C>,
  isLoading,
  submit,
  ...props
}: Props<C, E> & Partial<DropdownProps<data>>) => {
  return (
    <FormFieldSelect
      control={control}
      data={[
        { label: 'Male', value: Gender.Male },
        { label: 'Female', value: Gender.Female },
      ]}
      disable={disabled}
      errors={errors}
      fieldName={fieldName}
      isLoading={isLoading}
      label="Gender"
      onBlur={submit}
      required={false}
      style={props.style}
    />
  )
}

export default FormFieldGender
