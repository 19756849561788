import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import { router, Stack } from 'expo-router'
import { Platform } from 'react-native'
import { IconButton } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'
import Header from '../../../../components/navigation/header/Header'
import UserAvatarButton from '../../../../components/navigation/UserAvatarButton'

export const unstable_settings = {
  initialRouteName: 'index',
}

export const HeaderComponent = (props: NativeStackHeaderProps) => {
  return <Header {...props} />
}

export const HeaderRight = () => {
  return (
    <>
      {Platform.OS !== 'web' && (
        <IconButton
          icon="magnify"
          onPress={() => {
            router.push(`/search`)
          }}
          testID="search-icon-button"
        />
      )}

      <UserAvatarButton />
    </>
  )
}

const FosterRootLayout = () => {
  const { theme } = useStyles()

  return (
    <Stack
      screenOptions={{
        contentStyle: { backgroundColor: theme.colors.background },
        header: HeaderComponent,
        headerBackVisible: true,
        headerRight: HeaderRight,
        freezeOnBlur: false,
      }}
    />
  )
}

export default FosterRootLayout
