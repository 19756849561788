import { ReactNode } from 'react'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Surface, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

interface Props {
  children?: ReactNode
  style?: StyleProp<ViewStyle>
  title: string
  titleStyle?: StyleProp<TextStyle>
}

const Card = ({ children, title, titleStyle, style }: Props) => {
  const { styles } = useStyles(stylesheet)

  return (
    <Surface mode="flat" style={[styles.surfaceCard, style]} testID={title}>
      <Text style={[styles.title, titleStyle]} variant="headlineSmall">
        {title}
      </Text>
      {children}
    </Surface>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  surfaceCard: {
    backgroundColor: theme.colors.surface,
    borderColor: theme.colors.cardBorder,
    borderRadius: theme.tokens.containerBorderRadius,
    borderWidth: 1,
    flex: 1,
    height: 'auto',
    padding: theme.tokens.spacing[4],
  },
  title: {
    color: theme.colors.secondary,
    marginBottom: theme.tokens.spacing[4],
  },
}))

export default Card
