import { useEffect, useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { Avatar } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import InitialsAvatar from './InitialsAvatar'

interface Props {
  user: {
    emailHash?: string | null
    firstName: string
    lastName: string
  }
  size: number
  style?: ViewStyle
}

const UserAvatar = (props: Props) => {
  const { styles } = useStyles(stylesheet)

  const [hasGravatar, setHasGravatar] = useState<boolean | undefined>(undefined)

  // create a gravatar avatar link and test for a 404
  // if it fails, use the initials avatar
  const gravatarUrl = `https://www.gravatar.com/avatar/${props.user.emailHash}?d=404`

  useEffect(() => {
    fetch(gravatarUrl).then((response) => {
      setHasGravatar(response.status !== 404)
    })
  }, [gravatarUrl])

  if (hasGravatar === undefined) {
    return null
  }

  return (
    <View style={props.style}>
      {props.user.emailHash && hasGravatar ? (
        <Avatar.Image
          size={props.size}
          source={{
            uri: `https://www.gravatar.com/avatar/${props.user.emailHash}`,
          }}
          style={styles.avatar}
        />
      ) : (
        <InitialsAvatar
          size={30}
          string={`${props.user.firstName} ${props.user.lastName}`}
          style={{}}
        />
      )}
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    avatar: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderColor: theme.colors.backdrop,
      borderRadius: 100,
      // this doesn't pull from our theme because it's a very specific value
      //  to get around an issue where the border renders _under_ the image
      borderWidth: 16,
      justifyContent: 'center',
    },
  }
})

export default UserAvatar
