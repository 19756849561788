import { useNavigation } from 'expo-router'
import { useCallback, useEffect } from 'react'
import FosterService from '../../services/fosterService'
import FosterDetailHeaderContent from '../navigation/header/FosterDetailHeaderContent'
import Page from '../shared/Page'

const headerRight = () => <FosterDetailHeaderContent />

interface Props {
  children: React.ReactNode
  viewId: string
}

const FosterTab = (props: Props) => {
  const navigation = useNavigation()

  const { fosterId } = FosterService.useCurrentFoster()
  const { refreshFoster } = FosterService.useFoster(fosterId)

  const fosterName = FosterService.useCurrentFosterName()

  useEffect(() => {
    navigation.getParent()?.setOptions({
      title: fosterName || 'Add Foster',
      showSearchBar: false,
      headerRight,
    })
  }, [fosterName, navigation])

  const onRefresh = useCallback(async () => {
    await refreshFoster()
  }, [refreshFoster])

  return (
    <>
      <Page onRefresh={onRefresh} viewId={props.viewId}>
        {props.children}
      </Page>
    </>
  )
}

export default FosterTab
