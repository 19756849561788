import {
  ImagePrivacyLevel,
  SessionReplay,
  TextAndInputPrivacyLevel,
} from '@datadog/mobile-react-native-session-replay'
import {
  AutoInstrumentationConfiguration,
  PartialInitializationConfiguration,
} from '@datadog/mobile-react-native/lib/typescript/DdSdkReactNativeConfiguration'
import * as Application from 'expo-application'
import {
  BatchSize,
  DatadogProvider,
  DdRum,
  DdSdkReactNative,
  RumActionType,
  SdkVerbosity,
  UploadFrequency,
} from 'expo-datadog'
import { GestureResponderEvent } from 'react-native'
import { AppVersions } from '../../../constants'

export async function enableSessionReplay() {
  await SessionReplay.enable({
    replaySampleRate: 100,
    startRecordingImmediately: true,
    imagePrivacyLevel: ImagePrivacyLevel.MASK_NONE,
    textAndInputPrivacyLevel: TextAndInputPrivacyLevel.MASK_SENSITIVE_INPUTS,
  })
}

export async function evaluateFeature(feature: string, evaluation: boolean) {
  DdRum.addFeatureFlagEvaluation(feature, evaluation)
}

// Uses the following to delay the initialization https://docs.datadoghq.com/real_user_monitoring/mobile_and_tv_monitoring/react_native/advanced_configuration/#delaying-the-initialization
export function ddConfig(enabled: boolean) {
  const baseConfig: AutoInstrumentationConfiguration = {
    trackErrors: true,
    trackInteractions: true,
    trackResources: true,
    firstPartyHosts: ['api.rescuebase.dev', 'rescuebase2.fly.dev'],
    resourceTracingSamplingRate: 100,
  }

  if (!enabled) {
    return baseConfig
  }

  const config: PartialInitializationConfiguration = {
    serviceName: 'rescuebase-mobile',
    site: 'US1',
    longTaskThresholdMs: 100,
    nativeCrashReportEnabled: true,
    verbosity: SdkVerbosity.WARN,
    clientToken: process.env.EXPO_PUBLIC_DATADOG_CLIENT_TOKEN || '',
    env: process.env.NODE_ENV || 'local',
    applicationId: process.env.EXPO_PUBLIC_DATADOG_APPLICATION_ID || '',
    ...(enabled && __DEV__
      ? {
          sessionSamplingRate: 100,
          uploadFrequency: UploadFrequency.FREQUENT,
          batchSize: BatchSize.SMALL,
          verbosity: SdkVerbosity.DEBUG,
        }
      : {}),
  }

  if (Application.nativeApplicationVersion) {
    config.versionSuffix = AppVersions.gitSha || ''
  }

  // https://github.com/DataDog/expo-datadog/issues/28#issuecomment-2167983595
  // const bundleName =
  //   Platform.OS === 'android' ? 'index.android.bundle' : 'main.jsbundle'
  // config.errorEventMapper = (event) => {
  //   event.stacktrace = event.stacktrace.replace(
  //     /\(.*:(\d+):(\d+)\)$/gm,
  //     `(${bundleName}:$1:$2)`
  //   )
  //   return event
  // }

  return config
}

export async function initialize() {
  const config = ddConfig(true)

  await DatadogProvider.initialize(config as PartialInitializationConfiguration)
}

export function setUser(user: {
  id: string
  email: string
  firstName: string
  lastName: string
}) {
  DdSdkReactNative.setUser({
    email: user.email,
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
  })
}

export function stop() {
  DdRum.stopSession()
}

export function trackPageView(viewkey: string, pathname: string) {
  DdRum.startView(viewkey, pathname)
}

export function addAction({
  type,
  name,
  context,
  timestampMs,
  actionContext,
}: {
  type: RumActionType
  name: string
  context?: object
  timestampMs?: number
  actionContext?: GestureResponderEvent
}) {
  DdRum.addAction(type, name, context, timestampMs, actionContext)
}

export function startAction({
  type,
  name,
  context,
  timestampMs,
}: {
  type: RumActionType
  name: string
  context?: object
  timestampMs?: number
}) {
  DdRum.startAction(type, name, context, timestampMs)
}

export function stopAction({
  type,
  name,
  context,
  timestampMs,
}: {
  type: RumActionType
  name: string
  context?: object
  timestampMs?: number
}) {
  DdRum.stopAction(type, name, context, timestampMs)
}
