import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import { DocumentSelectorTypes as DocumentTypeSelectors } from '../../../../constants'
import DocumentType from '../../../enums/documentType'
import trpc from '../../../utils/trpc'
import FormFieldSelect from '../../form/FormFieldSelect'
import FormFieldText from '../../form/FormFieldText'

const formSchema = z.object({
  detailShort: z.string().optional().nullable(),
  type: z.nativeEnum(DocumentType),
})

type FormData = z.infer<typeof formSchema>

interface Props {
  documentId: string
  onSubmit: () => void
}

const DocumentEdit = ({ documentId, onSubmit }: Props) => {
  const { styles } = useStyles(stylesheet)

  const documentQuery = trpc.foster.documents.byId.useQuery(
    {
      id: documentId,
    },
    {
      enabled: true,
    }
  )

  const documentMutationQuery = trpc.foster.documents.update.useMutation()

  const defaultValues = useMemo(() => {
    return {
      detailShort: documentQuery.data?.detailShort,
      type: documentQuery.data?.type as DocumentType,
    }
  }, [documentQuery.data])

  const documentForm = useForm<FormData>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (documentQuery.data) {
      documentForm.reset({
        detailShort: documentQuery.data.detailShort,
        type: documentQuery.data.type as DocumentType,
      })
    }
  }, [documentForm, documentQuery.data])

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = documentForm

  const onInnerSubmit = handleSubmit(async (data) => {
    await documentMutationQuery.mutateAsync(
      {
        ...data,
        id: documentId,
      },
      {
        onSuccess: (updatedData) => {
          reset({
            ...updatedData,
            type: updatedData.type as DocumentType,
          })
          documentQuery.refetch()
        },
      }
    )

    documentQuery.refetch()

    onSubmit()
  })

  return (
    <View style={styles.root}>
      <View style={styles.formContainer}>
        <FormFieldSelect
          control={control}
          data={DocumentTypeSelectors}
          errors={errors}
          fieldName="type"
          label="Select Type"
          required={false}
        />
        <FormFieldText
          control={control}
          errors={errors}
          fieldName="detailShort"
          label="Short Description"
          required={false}
        />
        <Button
          disabled={!isDirty || documentMutationQuery.isLoading}
          loading={documentMutationQuery.isLoading}
          mode="contained"
          onPress={onInnerSubmit}
          style={{ marginTop: 5 }}
        >
          Update
        </Button>
      </View>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    formContainer: {
      alignSelf: {
        md: undefined,
        lg: 'center',
      },
      flex: {
        md: undefined,
        lg: 0.55,
      },
      flexDirection: 'column',
      gap: theme.tokens.spacing[2],
    },
    root: {
      flex: 1,
      flexDirection: {
        md: 'column',
        lg: 'row',
      },
      gap: theme.tokens.spacing[4],
    },
  }
})

export default DocumentEdit
