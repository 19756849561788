import { zodResolver } from '@hookform/resolvers/zod'
import { addWeeks } from 'date-fns'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button, IconButton, Modal, Portal, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import FormFieldDate from '../../form/FormFieldDate'

interface Props {
  isOpen: boolean
  onClose: (fecalFollowUpDate?: Date) => void
}

const formSchema = z.object({
  lastFecalMedDoseDate: z.date(),
})

const FosterFecalFollowupModal = ({ isOpen, onClose }: Props) => {
  const { styles } = useStyles(stylesheet)

  const { control, formState, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  })

  const onSubmit = handleSubmit((data) => {
    onClose(addWeeks(data.lastFecalMedDoseDate, 2))
  })

  return (
    <Portal>
      <Modal
        contentContainerStyle={styles.modalContent}
        onDismiss={onClose}
        style={styles.modal}
        visible={isOpen}
      >
        <View style={styles.modalHeader}>
          <Text style={styles.modalTitle}>Fecal Follow-Up</Text>
          <IconButton icon="close" onPress={() => onClose(undefined)} />
        </View>
        <View style={styles.modalBody}>
          <Text style={styles.modalBodyText}>
            You should bring in a new fecal sample two weeks after their last
            medication.
          </Text>
          <Text style={styles.modalBodyText}>
            We'll set the follow-up date and then send you an email reminder
            when it's time.
          </Text>
          <View style={styles.lastDosageContainer}>
            <Text style={styles.modalBodyText}>
              What day will they get their last dosage?
            </Text>
            <FormFieldDate
              control={control}
              errors={formState.errors}
              fieldName="lastFecalMedDoseDate"
              label="Last Dose"
              required
              style={styles.dateField}
            />
          </View>
        </View>
        <View style={styles.modalFooter}>
          <Button
            mode="contained"
            onPress={onSubmit}
            style={styles.modalButton}
          >
            OK
          </Button>
        </View>
      </Modal>
    </Portal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    dateField: {
      height: 50,
    },
    lastDosageContainer: {
      alignItems: 'center',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      gap: theme.tokens.spacing[4],
    },
    modal: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalBody: {
      marginBottom: theme.tokens.spacing[4],
    },
    modalBodyText: {
      fontSize: 16,
    },
    modalButton: {
      width: 100,
    },
    modalContent: {
      backgroundColor: theme.colors.background,
      padding: theme.tokens.spacing[4],
      width: '90%',
    },
    modalFooter: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    modalHeader: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.tokens.spacing[3],
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
    },
  }
})
export default FosterFecalFollowupModal
