import { zodResolver } from '@hookform/resolvers/zod'
import * as Linking from 'expo-linking'
import { useNavigation } from 'expo-router'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { CustomerInfo } from 'react-native-purchases'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import useAutoSave from '../../../hooks/useAutoSave'
import OrganizationService from '../../../services/organizationService'
import UserService from '../../../services/userService'
import purchases from '../../../utils/purchases/purchases'
import trpc from '../../../utils/trpc'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import OrganizationHeaderRight from '../OrganizationHeaderRight'
import File from './File'

const headerRight = () => <OrganizationHeaderRight />

const organizationFormSchema = z.object({
  name: z.string({ required_error: 'Name is required' }),
  microchipInfo: z.string().optional().nullable(),
  pdfFooter: z.string().optional().nullable(),
})

type OrganizationForm = z.infer<typeof organizationFormSchema>

interface Props {
  disabled: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrganizationInfoCard = ({ disabled }: Props) => {
  const { styles } = useStyles(stylesheet)
  const navigation = useNavigation()

  const { currentOrganization, refreshOrganization, organizationQueryData } =
    OrganizationService.useCurrentOrganization()

  const { user } = UserService.useCurrentUser()

  const [customerInfo, setCustomerInfo] = useState<CustomerInfo | null>(null)

  const organizationMutation = trpc.organization.update.useMutation()

  useEffect(() => {
    navigation.setOptions({
      headerRight,
    })
  }, [])

  const defaultValues = useMemo(() => {
    return {
      name: currentOrganization?.name || '',
      microchipInfo: organizationQueryData?.microchipInfo || '',
      pdfFooter: organizationQueryData?.pdfFooter || '',
    }
  }, [
    currentOrganization?.name,
    organizationQueryData?.microchipInfo,
    organizationQueryData?.pdfFooter,
  ])

  const form = useForm<OrganizationForm>({
    defaultValues: defaultValues,
    resolver: zodResolver(organizationFormSchema),
  })

  const { control, handleSubmit, formState, reset } = form

  const onSave = (data: OrganizationForm) => {
    if (!currentOrganization) {
      return null
    }

    organizationMutation.mutate(
      {
        organizationId: currentOrganization.id,
        name: data.name,
      },
      {
        onSuccess: () => {
          refreshOrganization()
        },
      }
    )
  }

  useAutoSave({
    form,
    defaultValues,
    onSave: handleSubmit(onSave),
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  useEffect(() => {
    if (!purchases.isConfigured()) {
      return
    }

    async function preload() {
      const ci = await purchases.getCustomerInfo()
      setCustomerInfo(ci)
    }

    preload()
  }, [])

  const entitlement = customerInfo?.entitlements.active?.premium

  const isSubscribed = !!entitlement?.isActive
  const expirationDate = entitlement?.expirationDate

  const previousSubscription = customerInfo?.entitlements.all.premium

  if (!user) {
    return null
  }

  const onUploadComplete = (
    fileIds: string[],
    fileType: 'logo' | 'qrCode' | 'adoptionContract'
  ) => {
    if (!currentOrganization) {
      return
    }

    organizationMutation.mutate(
      {
        name: currentOrganization.name,
        organizationId: currentOrganization.id,
        logoId: fileType === 'logo' ? fileIds[0] : undefined,
        qrCodeId: fileType === 'qrCode' ? fileIds[0] : undefined,
        adoptionContractId:
          fileType === 'adoptionContract' ? fileIds[0] : undefined,
      },
      {
        onSuccess: () => {
          refreshOrganization()
        },
      }
    )
  }

  const onDelete = () => {
    if (!currentOrganization) {
      return
    }

    organizationMutation.mutate(
      {
        name: currentOrganization.name,
        organizationId: currentOrganization.id,
        logoId: currentOrganization.logo?.id || null,
        qrCodeId: currentOrganization.qrCode?.id || null,
        adoptionContractId: currentOrganization.adoptionContract?.id || null,
      },
      {
        onSuccess: () => {
          refreshOrganization()
        },
      }
    )
  }

  if (!currentOrganization) {
    return null
  }

  return (
    <Card style={{ gap: 12 }} title="Info">
      <Text variant="titleLarge">Basic Info</Text>
      <FormFieldText
        control={control}
        disabled={!currentOrganization.subscriptionActive}
        errors={formState.errors}
        fieldName="name"
        label="Name"
        required
      />
      <FormFieldText
        control={control}
        disabled={!currentOrganization.subscriptionActive}
        errors={formState.errors}
        fieldName="microchipInfo"
        label="Microchip Info"
        multiline
        required={false}
      />
      <FormFieldText
        control={control}
        disabled={!currentOrganization.subscriptionActive}
        errors={formState.errors}
        fieldName="pdfFooter"
        label="PDF Footer"
        multiline
        required={false}
      />
      <File
        allowedFileTypes={['image/jpeg', 'image/png']}
        fileId={currentOrganization?.logo?.id || ''}
        fileName={currentOrganization?.logo?.name}
        mimeType={currentOrganization?.logo?.mimeType}
        name="Logo"
        onDelete={() => {
          currentOrganization.logo = null
          onDelete()
        }}
        onUploadComplete={(fileIds) => onUploadComplete(fileIds, 'logo')}
        thumbURL={currentOrganization?.logo?.url}
      />

      <Text variant="titleLarge">Documents</Text>
      <View style={{ flexDirection: 'row' }}>
        <File
          allowedFileTypes={['application/pdf']}
          fileId={currentOrganization?.adoptionContract?.id || ''}
          fileName={currentOrganization?.adoptionContract?.name}
          mimeType={currentOrganization?.adoptionContract?.mimeType}
          name="Adoption Contract"
          onDelete={() => {
            currentOrganization.adoptionContract = null
            onDelete()
          }}
          onUploadComplete={(fileIds) =>
            onUploadComplete(fileIds, 'adoptionContract')
          }
          thumbURL={currentOrganization?.adoptionContract?.url}
        />
        <File
          allowedFileTypes={['image/jpeg', 'image/png']}
          fileId={currentOrganization?.qrCode?.id || ''}
          fileName={currentOrganization?.qrCode?.name}
          mimeType={currentOrganization?.qrCode?.mimeType}
          name="QR Code"
          onDelete={() => {
            currentOrganization.qrCode = null
            onDelete()
          }}
          onUploadComplete={(fileIds) => onUploadComplete(fileIds, 'qrCode')}
          thumbURL={currentOrganization?.qrCode?.url}
        />
      </View>
      <Text variant="titleLarge">Billing</Text>
      {!isSubscribed &&
        previousSubscription &&
        previousSubscription.expirationDate && (
          <Text>
            Subscription Expired On:{' '}
            {new Date(previousSubscription.expirationDate).toDateString()}
          </Text>
        )}
      {isSubscribed ? (
        <>
          <Text>
            Expires: {expirationDate && new Date(expirationDate).toDateString()}
          </Text>
          <Text>
            Subscription Status: {expirationDate ? 'Active' : 'Inactive'}
          </Text>
          <Text>
            Member Since:{' '}
            {entitlement?.originalPurchaseDate &&
              new Date(entitlement?.originalPurchaseDate).toDateString()}
          </Text>

          {customerInfo?.managementURL && (
            <Button
              mode="contained"
              onPress={() => Linking.openURL(customerInfo?.managementURL || '')}
              style={styles.button}
            >
              Manage Subscription
            </Button>
          )}
        </>
      ) : (
        <Button
          mode="contained"
          onPress={async () => {
            purchases.showPaywall()
          }}
          style={styles.button}
        >
          Subscribe
        </Button>
      )}
    </Card>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    button: {
      marginTop: theme.tokens.spacing[4],
      width: 200,
    },
  }
})

export default OrganizationInfoCard
