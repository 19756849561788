import OrganizationService from '../../services/organizationService'
import SaveStatus from '../shared/SaveStatus'

const OrganizationHeaderRight = () => {
  const isSaving = OrganizationService.useIsSaving()
  const { organizationQueryData } = OrganizationService.useCurrentOrganization()

  return (
    <SaveStatus
      isSaving={isSaving}
      updatedAt={organizationQueryData?.updatedAt}
      // updatedBy={`${foster?.updatedBy.firstName} ${foster?.updatedBy.lastName}`}
    />
  )
}

export default OrganizationHeaderRight
