import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import debounce from 'debounce'
import { Stack } from 'expo-router'
import { useState } from 'react'
import SearchHeader from '../../../components/search/SearchHeader'
import SearchResults from '../../../components/search/SearchResults'
import Page from '../../../components/shared/Page'

const Header = (
  props: NativeStackHeaderProps,
  onChangeText: (text: string) => void
) => <SearchHeader {...props} onChangeText={onChangeText} />

const Search = () => {
  const [searchInput, setSearchInput] = useState('')
  const debouncedSetSearchInput = debounce(setSearchInput, 200)

  if (!searchInput && searchInput !== '') {
    debouncedSetSearchInput('')
    return
  }

  const onChangeText = (text: string) => {
    debouncedSetSearchInput(text)
  }

  return (
    <>
      <Stack.Screen
        options={{
          title: 'Search',
          headerBackVisible: false,
          animation: 'slide_from_bottom',
          animationDuration: 200,
          header: (props) => Header(props, onChangeText),
        }}
      />

      <Page viewId="search-results">
        <SearchResults searchInput={searchInput} />
      </Page>
    </>
  )
}

export default Search
