import FontAwesome from '@expo/vector-icons/FontAwesome'
import { isSameDay } from 'date-fns'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { DashboardDay } from './DashboardTypes'

interface Props {
  day: DashboardDay
}

const DashboardTimeline = ({ day }: Props) => {
  const { styles, theme } = useStyles(stylesheet)
  return (
    <View style={styles.root}>
      <View style={styles.dateLabelContainer}>
        {day.isOverdue ? (
          <View style={styles.overdueLabelContainer}>
            <FontAwesome
              color={theme.colors.error}
              name="exclamation-circle"
              size={30}
            />
            <Text style={styles.dateLabelSmallFont}>Past</Text>
            <Text style={styles.dateLabelSmallFont}>Due</Text>
          </View>
        ) : (
          <>
            <Text style={styles.dateLabelSmallFont}>
              {day.dueDate.toLocaleDateString('en-US', {
                weekday: 'short',
              })}
            </Text>
            <View
              style={
                isSameDay(day.dueDate, new Date())
                  ? styles.dateLabelTodayContainer
                  : {}
              }
            >
              <Text
                style={
                  isSameDay(day.dueDate, new Date())
                    ? styles.dateLabelToday
                    : styles.dateLabelMediumFont
                }
              >
                {(day.dueDate || new Date()).toLocaleDateString('en-US', {
                  day: 'numeric',
                })}
              </Text>
            </View>
          </>
        )}
      </View>

      {day.fosters.length > 1 && (
        <View
          style={[
            styles.timelineBar,
            day.isOverdue
              ? styles.timelineBarOverdueColor
              : styles.timelineBarColor,
          ]}
        />
      )}
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    dateLabelContainer: {
      alignItems: 'center',
      height: 70,
      justifyContent: 'center',
      width: 60,
    },
    dateLabelMediumFont: {
      fontSize: 24,
    },
    dateLabelSmallFont: {
      fontSize: 14,
    },
    dateLabelToday: {
      color: theme.colors.onPrimary,
      fontSize: 24,
    },
    dateLabelTodayContainer: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: 100,
      height: 44,
      justifyContent: 'center',
      width: 44,
    },
    overdueLabelContainer: {
      alignItems: 'center',
      flex: 1,
    },
    root: {
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: -3,
    },
    timelineBar: {
      alignSelf: 'center',
      borderRadius: 100,
      flex: 1,
      width: 4,
    },
    timelineBarColor: {
      backgroundColor: theme.colors.secondary,
    },
    timelineBarOverdueColor: {
      backgroundColor: theme.colors.error,
    },
  }
})

export default DashboardTimeline
