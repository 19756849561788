import { DrawerNavigationOptions } from '@react-navigation/drawer'
import { getHeaderTitle } from '@react-navigation/elements'
import { RouteProp } from '@react-navigation/native'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { router, useNavigation } from 'expo-router'
import { useEffect, useState } from 'react'
import { Platform, View } from 'react-native'
import { Appbar, IconButton } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'

const Header = ({
  children,
  replaceContentWithChildren,
  route,
  title,
  options,
}: {
  children?: React.ReactNode
  replaceContentWithChildren?: boolean
  route: RouteProp<Record<string, object | undefined>, string>
  title?: string
  options: DrawerNavigationOptions | NativeStackNavigationOptions
}) => {
  const { theme } = useStyles()
  const navigation = useNavigation()
  const [showBackButton, setShowBackButton] = useState(false)

  useEffect(() => {
    setShowBackButton(navigation.canGoBack())
    // avoid re-rendering when options changes to avoid render jank with the back button briefly flickering
    // on the previous screen while navigation is happening
  }, [navigation])

  return (
    <Appbar.Header
      elevated={false}
      style={{
        paddingLeft: showBackButton ? 0 : theme.tokens.spacing[4],
        paddingRight: headerXPadding,
        backgroundColor: 'transparent', // Set to transparent to allow Animated.View to control background
      }}
    >
      <>
        {showBackButton && (
          <IconButton
            icon="arrow-left"
            iconColor={theme.colors.primary}
            onPress={() => {
              router.back()
            }}
            testID="back-icon-button"
          />
        )}

        {replaceContentWithChildren ? (
          children
        ) : (
          <>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Appbar.Content
                style={{ flex: Platform.OS === 'web' ? 0.4 : 1 }}
                title={title || getHeaderTitle(options, route.name)}
              />
              {children}
            </View>
            {options.headerRight && (
              <>{options.headerRight({ canGoBack: showBackButton })}</>
            )}
          </>
        )}
      </>
    </Appbar.Header>
  )
}

const headerXPadding = 16

export default Header
