import { useCallback } from 'react'
import FosterService from '../../services/fosterService'
import { useAppStore } from '../../store/useAppStore'
import Page from '../shared/Page'
import FosterAdoptionCard from './cards/FosterAdoptionsCard'
import FosterDocumentsCard from './cards/FosterDocumentsCard'
import FosterHealthCard from './cards/FosterHealthCard'
import FosterNotesCard from './cards/FosterNotesCard'
import FosterProfileCard from './cards/FosterProfileCard'
import useFosterForm from './useFosterForm'

const FosterPage = () => {
  const { fosterId } = FosterService.useCurrentFoster()
  const { refreshFoster } = FosterService.useFoster(fosterId)

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const form = useFosterForm()

  const { control, formState, setValue } = form

  const onRefresh = useCallback(async () => {
    await refreshFoster()
  }, [refreshFoster])

  return (
    <Page onRefresh={onRefresh} viewId="foster-detail-scroll-view">
      <FosterProfileCard
        control={control}
        disabled={!currentOrganization?.subscriptionActive}
        formState={formState}
      />
      <FosterHealthCard
        control={control}
        disabled={!currentOrganization?.subscriptionActive}
        formState={formState}
        setValue={setValue}
      />
      <FosterNotesCard
        control={control}
        disabled={!currentOrganization?.subscriptionActive}
        formState={formState}
      />
      <FosterAdoptionCard
        control={control}
        disabled={!currentOrganization?.subscriptionActive}
        formState={formState}
      />
      <FosterDocumentsCard
        disabled={!currentOrganization?.subscriptionActive}
      />
    </Page>
  )
}

export default FosterPage
