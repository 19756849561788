import { createStyleSheet } from 'react-native-unistyles'

const styles = createStyleSheet((theme) => {
  return {
    buttonRow: {
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      gap: theme.tokens.spacing[3],
      marginTop: theme.tokens.spacing[3],
    },
  }
})

export default styles
