import { StateCreator } from 'zustand'

export interface AuthSlice {
  auth: {
    accessToken: string | undefined
    isLoggedIn: boolean | undefined
  }

  setAuth: (auth: {
    accessToken: string | undefined
    isLoggedIn: boolean | undefined
  }) => void
}

export const createAuthSlice: StateCreator<AuthSlice> = (set) => ({
  auth: {
    accessToken: undefined,
    isLoggedIn: undefined,
  },
  setAuth: ({ accessToken, isLoggedIn }) => {
    set({ auth: { accessToken, isLoggedIn } })
  },
})
