import React, { createContext, useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FosterForm } from './fosterFormSchema'
import useFosterForm from './useFosterForm'

const FosterFormContext = createContext<UseFormReturn<FosterForm> | undefined>(
  undefined
)

export const useFosterFormContext = () => {
  const context = useContext(FosterFormContext)
  if (!context) {
    throw new Error(
      'useFosterFormContext must be used within a FosterFormProvider'
    )
  }
  return context
}

export const FosterFormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const form = useFosterForm()
  const { control, formState, setValue } = form
  return (
    <FosterFormContext.Provider
      value={{ ...form, control, formState, setValue }}
    >
      {children}
    </FosterFormContext.Provider>
  )
}
