import { useState } from 'react'
import { View } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import trpc from '../../utils/trpc'
import SettingsModal from './SettingsModal'
import UserAvatar from './UserAvatar'

const UserAvatarButton = () => {
  const { styles } = useStyles(stylesheet)

  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  const currentUser = trpc.user.current.useQuery({})

  if (!currentUser.data) {
    return null
  }

  return (
    <View testID="avatar-settings-button">
      <TouchableRipple
        accessibilityRole="button"
        borderless
        onPress={showModal}
        style={styles.root}
      >
        <UserAvatar
          size={30}
          user={{
            emailHash: currentUser.data.emailHash ?? undefined,
            firstName: currentUser.data.firstName,
            lastName: currentUser.data.lastName,
          }}
        />
      </TouchableRipple>
      <SettingsModal hideModal={hideModal} visible={modalVisible} />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    root: {
      borderRadius: 100,
      margin: theme.tokens.spacing[4],
    },
  }
})

export default UserAvatarButton
