import { ColorValue, StyleProp, View, ViewStyle } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

type OutlineProps = {
  activeColor: string
  backgroundColor: ColorValue
  hasActiveOutline?: boolean
  outlineColor?: string
  roundness?: number
  label?: boolean
  style?: StyleProp<ViewStyle>
}

const Outline = ({
  label,
  activeColor,
  backgroundColor,
  hasActiveOutline,
  outlineColor,
  roundness,
  style,
}: OutlineProps) => {
  const { styles } = useStyles(stylesheet)

  return (
    <View
      pointerEvents="none"
      style={[
        styles.outline,
        !label && styles.noLabelOutline,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          backgroundColor,
          borderRadius: roundness,
          borderWidth: hasActiveOutline ? 2 : 1,
          borderColor: hasActiveOutline ? activeColor : outlineColor,
        },
        style,
      ]}
      testID="text-input-outline"
    />
  )
}

const stylesheet = createStyleSheet({
  noLabelOutline: {
    top: 0,
  },
  outline: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 6,
  },
})

export default Outline
