import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'
import Card from '../../shared/Card'
import OrganizationSettingTable from './OrganizationSettingTable'

interface Props {
  disabled: boolean
}

const OrganizationVaccinesCard = ({ disabled }: Props) => {
  const currentOrganization = useAppStore.use.currentOrganization().organization

  const vaccineListQuery = trpc.vaccine.list.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization,
    }
  )

  if (!vaccineListQuery.data?.filter(Boolean).length) {
    return null
  }

  return (
    <Card title="Vaccines">
      <OrganizationSettingTable
        data={vaccineListQuery.data}
        disabled={disabled}
        editRoute={`/organization/${currentOrganization?.id}/vaccines`}
      />
    </Card>
  )
}

export default OrganizationVaccinesCard
