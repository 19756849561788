import { getHeaderTitle } from '@react-navigation/elements'
import { RouteProp } from '@react-navigation/native'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { router } from 'expo-router'
import { Platform, View } from 'react-native'
import { Appbar, Button, IconButton } from 'react-native-paper'
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../../store/useAppStore'
import DesktopSearchBar from '../DesktopSearchBar'
import Header from './Header'

const FosterHeader = ({
  route,
  title,
  options,
}: {
  route: RouteProp<Record<string, object | undefined>, string>
  title?: string
  options: NativeStackNavigationOptions
}) => {
  const { theme } = useStyles()
  const bulkSelect = useAppStore.use.bulkSelect()
  const resetBulkSelect = useAppStore.use.resetBulkSelect()

  // Reanimated shared value for animation
  const animation = useSharedValue(0)

  // Update animation value based on selection state
  const isSelectionActive =
    bulkSelect.selection.items.length > 0 && route.name === 'index'
  animation.value = withTiming(isSelectionActive ? 1 : 0, {
    duration: 300,
  })

  // Animated style for header background color
  const animatedStyle = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      animation.value,
      [0, 1],
      [theme.colors.background, theme.colors.secondaryContainer]
    )

    return { backgroundColor }
  })

  return (
    <Animated.View style={animatedStyle}>
      <Header
        options={options}
        replaceContentWithChildren={true}
        route={route}
        title={title}
      >
        {isSelectionActive ? (
          <>
            <IconButton
              icon="close"
              iconColor={theme.colors.onSecondaryContainer}
              onPress={() => resetBulkSelect()}
              testID="clear-selection-button"
            />
            <Appbar.Content
              title={`${bulkSelect.selection.items.length} Selected`}
            />
            <Button
              onPress={() => {
                router.push({
                  pathname: 'fosters/bulk',
                  params: { ids: bulkSelect.selection.items },
                })
              }}
            >
              Edit All
            </Button>
          </>
        ) : (
          <>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Appbar.Content
                style={{ flex: Platform.OS === 'web' ? 0.4 : 1 }}
                title={title || getHeaderTitle(options, route.name)}
              />

              {Platform.OS === 'web' && (
                <View
                  style={{
                    alignItems: 'center',
                    bottom: -18,
                    left: '50%',
                    position: 'absolute',
                    transform: [{ translateX: '-50%' }],
                    width: '100%',
                  }}
                >
                  <DesktopSearchBar />
                </View>
              )}
            </View>

            {options.headerRight && (
              <>{options.headerRight({ canGoBack: true })}</>
            )}
          </>
        )}
      </Header>
    </Animated.View>
  )
}

export default FosterHeader
