import { useActionSheet } from '@expo/react-native-action-sheet'
import { Image } from 'expo-image'
import { createRef, memo, useCallback, useState } from 'react'
import { Alert, findNodeHandle, StyleSheet, View } from 'react-native'
import {
  ActivityIndicator,
  Icon,
  Text,
  TouchableRipple,
} from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { ApiRoot } from '../../../../constants'
import useFile from '../../../hooks/useFile'
import { useAppStore } from '../../../store/useAppStore'
import log from '../../../utils/datadog/log/log'
import { logError } from '../../../utils/log'
import trpc from '../../../utils/trpc'
import UserAvatar from '../../navigation/UserAvatar'
import DocumentEditModal from '../documents/DocumentEditModal'

const ASPECT_RATIO = 1.5
const MAX_HEIGHT = 400
const MAX_WIDTH = MAX_HEIGHT * ASPECT_RATIO

interface Props {
  createdAt?: Date
  detailShort?: string
  documentId: string
  fileName?: string
  fileId: string
  mimeType?: string
  testID?: string
  uploadedBy: {
    emailHash?: string | null
    firstName: string
    lastName: string
  }
  onUpdate: () => void
  onDelete: () => void
}

const Document = ({
  createdAt,
  detailShort,
  documentId,
  fileId,
  fileName,
  mimeType,
  testID,
  uploadedBy,
  onUpdate,
  onDelete,
}: Props) => {
  const { styles } = useStyles(stylesheet)
  const { showActionSheetWithOptions } = useActionSheet()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false)

  const accessToken = useAppStore.use.auth().accessToken

  const _anchorRef = createRef<any>()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  // pre-warm the query for the edit modal
  trpc.foster.documents.byId.useQuery(
    {
      id: documentId,
    },
    {
      enabled: true,
    }
  )

  const { openFile } = useFile()

  const url = `${ApiRoot()}/${currentOrganization?.id}/${fileId}/download?width=${MAX_WIDTH * 2}&height=${MAX_HEIGHT * 2}`

  const edit = () => {
    console.log('edit')
  }

  const open = useCallback(async () => {
    if (!currentOrganization?.id) {
      return
    }

    setIsDownloading(true)

    try {
      await openFile({
        fileName,
        fileId,
        mimeType,
      })
    } catch (error: any) {
      log.error('There was a problem opening the file')
      logError(error)
    }

    setIsDownloading(false)
  }, [currentOrganization?.id, fileId, fileName, mimeType, openFile])

  const onLongPress = () => {
    const options = ['Edit', 'Delete', 'Cancel']
    const onEdit = 0
    const destructiveButtonIndex = 1
    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        anchor: findNodeHandle(_anchorRef.current) || undefined,
        useModal: false,
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (selectedIndex: number | undefined) => {
        switch (selectedIndex) {
          case onEdit:
            setIsEditingModalOpen(true)
            break
          case destructiveButtonIndex:
            Alert.alert(
              'Delete',
              'Are you sure you want to delete this document?',
              [
                {
                  text: 'Cancel',
                  onPress: () => console.log('Cancel Pressed'),
                  style: 'cancel',
                },
                {
                  isPreferred: false,
                  text: 'Delete',
                  onPress: () => onDelete(),
                  style: 'destructive',
                },
              ]
            )
            break

          case cancelButtonIndex:
            break
        }
      }
    )
  }

  const iconSource = mimeType?.startsWith('image/') ? 'image' : 'file-pdf-box'

  return (
    <TouchableRipple
      accessibilityRole="button"
      borderless
      disabled={isDownloading}
      onLongPress={onLongPress}
      onPress={mimeType ? open : edit}
      ref={_anchorRef}
      style={styles.root}
      testID={testID}
    >
      <View style={[{ padding: 16 }]}>
        <View style={styles.documentCardHeader}>
          <UserAvatar size={30} user={uploadedBy} />
          <View style={styles.documentCardHeaderContainer}>
            <Text style={styles.fileMetadataText}>
              {uploadedBy.firstName} {uploadedBy.lastName}
            </Text>
            <Text style={styles.fileMetadataText}>
              {createdAt?.toLocaleDateString()}
            </Text>
          </View>
        </View>
        <View style={styles.fileContainer}>
          <>
            {isDownloading && (
              <ActivityIndicator
                animating={true}
                size={MAX_HEIGHT / 10}
                style={styles.spinner}
              />
            )}
            {mimeType?.startsWith('image/') && url ? (
              <Image
                accessibilityIgnoresInvertColors={true}
                allowDownscaling={true}
                contentFit="cover"
                source={{
                  uri: url,
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }}
                style={{
                  ...StyleSheet.absoluteFillObject,
                  height: undefined,
                  width: undefined,
                }}
              />
            ) : (
              <Icon size={30} source={iconSource} />
            )}
          </>
        </View>
        {detailShort && detailShort.length > 0 && (
          <Text style={styles.fileMetadataText} testID="document-detail-short">
            {detailShort}
          </Text>
        )}
        <DocumentEditModal
          documentId={documentId}
          onClose={() => {
            setIsEditingModalOpen(false)
            onUpdate()
          }}
          open={isEditingModalOpen}
        />
      </View>
    </TouchableRipple>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    documentCardHeader: {
      alignItems: 'center',
      flexDirection: 'row',
      paddingBottom: 8,
      paddingHorizontal: 4,
    },
    documentCardHeaderContainer: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    fileContainer: {
      alignItems: 'center',
      borderColor: theme.colors.cardBorder,
      borderRadius: 4,
      borderWidth: 1,
      height: MAX_HEIGHT / 2,
      justifyContent: 'center',
    },
    fileMetadataText: {
      color: theme.colors.subtitle,
      padding: theme.tokens.spacing[3],
    },
    root: {
      backgroundColor: theme.colors.surface,
      borderColor: theme.colors.cardBorder,
      borderRadius: 8,
      borderWidth: 1,
      color: theme.colors.onBackground,
      flex: {
        xs: 1,
        sm: 0.5,
      },
      flexBasis: {
        xs: '80%',
        sm: '47%',
      },
      flexGrow: {
        xs: 1,
        sm: 0,
      },
    },
    spinner: {
      position: 'absolute',
      top: MAX_HEIGHT / 4 - MAX_HEIGHT / 20,
      zIndex: 100,
    },
  }
})

export default memo(Document)
