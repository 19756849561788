import FosterHealthCard from '../../../../components/fosters/cards/FosterHealthCard'
import { useFosterFormContext } from '../../../../components/fosters/FosterFormContext'
import FosterTab from '../../../../components/fosters/FosterTab'
import { useAppStore } from '../../../../store/useAppStore'

const FosterMedicalTab = () => {
  const { control, formState, setValue } = useFosterFormContext()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  return (
    <FosterTab viewId="foster-health-tab">
      <FosterHealthCard
        control={control}
        disabled={!currentOrganization?.subscriptionActive}
        formState={formState}
        setValue={setValue}
      />
    </FosterTab>
  )
}

export default FosterMedicalTab
