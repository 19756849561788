import { useState } from 'react'
import {
  Control,
  FormState,
  Path,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form'
import { View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { FosterBulkForm } from '../../../app/(app)/fosters/bulk'
import Gender from '../../../enums/gender'
import MedicalInfoType from '../../../enums/medicalInfoType'
import Species from '../../../enums/species'
import OrganizationService from '../../../services/organizationService'
import FormFieldCheckbox from '../../form/FormFieldCheckbox'
import FormFieldDate from '../../form/FormFieldDate'
import Card from '../../shared/Card'
import InfoText from '../../shared/InfoText'
import { FosterForm } from '../fosterFormSchema'
import useFosterForm from '../useFosterForm'
import FosterFecalFollowupModal from './FosterFecalFollowupModal'
import fosterSharedStyles from './fosterSharedStyles'

interface Props<T extends FosterForm | FosterBulkForm> {
  children?: React.ReactNode
  control: Control<T>
  disabled: boolean
  formState: FormState<T>
  isLoading: boolean
  setValue: UseFormSetValue<T>
  species?: Species
  submit?: () => void
}

const FosterMedicalForm = <T extends FosterForm | FosterBulkForm>({
  children,
  control,
  disabled,
  formState,
  isLoading,
  setValue,
  species,
  submit,
}: Props<T>) => {
  const { styles } = useStyles(fosterMedicalFormStylesheet)
  const { styles: sharedStyles } = useStyles(fosterSharedStyles)
  const [fecalFollowUpModalOpen, setFecalFollowUpModalOpen] = useState(false)
  const { watch } = useFosterForm()
  const gender = watch('gender')
  const birthDate = watch('birthDate')
  const positiveFecalTestDate = watch('positiveFecalTestDate')
  const spayNeuter =
    gender === Gender.Male
      ? 'Neuter'
      : gender === Gender.Female
        ? 'Spay'
        : undefined

  const fecalMedicalInfos = OrganizationService.useMedicalInfo(
    MedicalInfoType.Fecal,
    birthDate,
    species
  )
  const preventionMedicalInfos = OrganizationService.useMedicalInfo(
    MedicalInfoType.Prevention,
    birthDate,
    species
  )
  const spayNeuterMedicalInfos = OrganizationService.useMedicalInfo(
    MedicalInfoType.SpayAndNeuter,
    birthDate,
    species
  )

  const onFecalFollowUpModalClose = (fecalFollowUpDate?: Date) => {
    if (fecalFollowUpDate) {
      setValue(
        'fecalFollowUpDate' as Path<T>,
        fecalFollowUpDate as PathValue<T, Path<T>>
      )
    }

    setFecalFollowUpModalOpen(false)
  }

  useDeepCompareEffectNoCheck(() => {
    if (
      positiveFecalTestDate &&
      positiveFecalTestDate instanceof Date &&
      (formState.dirtyFields as FosterForm | FosterBulkForm)
        .positiveFecalTestDate
    ) {
      setFecalFollowUpModalOpen(true)
    }
  }, [positiveFecalTestDate])

  return (
    <Card title="Medical Info">
      <FosterFecalFollowupModal
        isOpen={fecalFollowUpModalOpen}
        onClose={onFecalFollowUpModalClose}
      />
      <View style={sharedStyles.textInputContainer}>
        {!species && (
          <>
            {children && children}
            <InfoText text={fecalMedicalInfos?.[0]?.text} />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'positiveFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Positive Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'fecalFollowUpDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Fecal Follow-up Test Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'clearFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="✔️ Clear Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <View style={styles.medicalInfoInput}>
              <InfoText text={spayNeuterMedicalInfos?.[0]?.text} />
              <FormFieldDate
                control={control}
                disabled={disabled}
                errors={formState.errors}
                fieldName={'fixedOnDate' as Path<T>}
                isLoading={isLoading}
                label={`✂️ ${spayNeuter || 'Spay/Neuter'} Date`}
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <FormFieldCheckbox
                control={control}
                disabled={disabled}
                fieldName={'fixedBeforeIntake' as Path<T>}
                formState={formState}
                isLoading={isLoading}
                label={
                  spayNeuter
                    ? `${spayNeuter}ed before intake`
                    : 'Spayed/Neutered before intake'
                }
                onBlur={submit}
                required={false}
                status="checked"
              />
            </View>
          </>
        )}
        {species === Species.Dog && (
          <>
            {children && children}
            {/* <Skeleton isLoading={isLoading}> */}
            <InfoText text={fecalMedicalInfos?.[0]?.text} />
            {/* </Skeleton> */}
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'positiveFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Positive Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'fecalFollowUpDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Fecal Follow-up Test Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'clearFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="✔️ Clear Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={disabled}
                errors={formState.errors}
                fieldName={'heartwormTestingCompletedDate' as Path<T>}
                isLoading={isLoading}
                label="✔️ Heartworm Test Completed"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <FormFieldCheckbox
                control={control}
                disabled={disabled}
                fieldName={'heartwormPositive' as Path<T>}
                formState={formState}
                isLoading={isLoading}
                label="Heartworm Positive"
                onBlur={submit}
                required={false}
                status="checked"
              />
            </View>
            <InfoText text={preventionMedicalInfos?.[0]?.text} />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'nextHeartwormPreventionDue' as Path<T>}
              isLoading={isLoading}
              label="💊 Next Heartworm Prev. Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'nextFleaTickMedDue' as Path<T>}
              isLoading={isLoading}
              label="💊 Next Flea/Tick Prev. Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <InfoText text={spayNeuterMedicalInfos?.[0]?.text} />
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={disabled}
                errors={formState.errors}
                fieldName={'fixedOnDate' as Path<T>}
                isLoading={isLoading}
                label={`✂️ ${spayNeuter || 'Spay/Neuter'} Date`}
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />

              <FormFieldCheckbox
                control={control}
                disabled={disabled}
                fieldName={'fixedBeforeIntake' as Path<T>}
                formState={formState}
                isLoading={isLoading}
                label={
                  spayNeuter
                    ? `${spayNeuter}ed before intake`
                    : 'Spayed/Neutered before intake'
                }
                onBlur={submit}
                required={false}
                status="checked"
              />
            </View>
          </>
        )}
        {species === Species.Cat && (
          <>
            {children && children}
            <InfoText text={fecalMedicalInfos?.[0]?.text} />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'positiveFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Positive Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'fecalFollowUpDate' as Path<T>}
              isLoading={isLoading}
              label="💩 Fecal Follow-up Test Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'clearFecalTestDate' as Path<T>}
              isLoading={isLoading}
              label="✔️ Clear Fecal Test Date"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={disabled}
                errors={formState.errors}
                fieldName={'comboTestingCompletedDate' as Path<T>}
                isLoading={isLoading}
                label="✔️ Combo Test Completed"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <View style={styles.medicalInfoCatComboCheckboxContainer}>
                <FormFieldCheckbox
                  control={control}
                  disabled={disabled}
                  fieldName={'fivPositive' as Path<T>}
                  formState={formState}
                  isLoading={isLoading}
                  label="FIV Positive"
                  onBlur={submit}
                  required={false}
                  status="checked"
                />
                <FormFieldCheckbox
                  control={control}
                  disabled={disabled}
                  fieldName={'felvPositive' as Path<T>}
                  formState={formState}
                  isLoading={isLoading}
                  label="FeLV Positive"
                  onBlur={submit}
                  required={false}
                  status="checked"
                />
              </View>
            </View>
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'nextComboMedDue' as Path<T>}
              isLoading={isLoading}
              label="💊 Next Meds Due"
              onBlur={submit}
              required={false}
              style={styles.medicalInfoInput}
            />

            <InfoText text={spayNeuterMedicalInfos?.[0]?.text} />
            <View style={styles.medicalInfoInput}>
              <FormFieldDate
                control={control}
                disabled={disabled}
                errors={formState.errors}
                fieldName={'fixedOnDate' as Path<T>}
                isLoading={isLoading}
                label={`✂️ ${spayNeuter || 'Spay/Neuter'} Date`}
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />

              <FormFieldCheckbox
                control={control}
                disabled={disabled}
                fieldName={'fixedBeforeIntake' as Path<T>}
                formState={formState}
                isLoading={isLoading}
                label={
                  spayNeuter
                    ? `${spayNeuter}ed before intake`
                    : 'Spayed/Neutered before intake'
                }
                onBlur={submit}
                required={false}
                status="checked"
              />
            </View>
          </>
        )}
      </View>
    </Card>
  )
}

export default FosterMedicalForm

export const fosterMedicalFormStylesheet = createStyleSheet(() => {
  return {
    medicalInfoCatComboCheckboxContainer: {
      flexDirection: 'row',
    },
    medicalInfoInput: {
      flexBasis: {
        xs: '50%',
        sm: '40%',
        lg: '20%',
      },
      flexGrow: 1,
      minWidth: 150,
    },
  }
})
