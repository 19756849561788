import { StateCreator } from 'zustand'

interface File {
  id: string
  name: string
  mimeType: string
  url: string | null
}

export interface OrganizationSlice {
  currentOrganization: {
    organization?: {
      id: string
      adoptionContract: File | null
      logo: File | null
      qrCode: File | null
      name: string
      subscriptionActive?: boolean
    }
  }
  setCurrentOrganization: (currentOrganization: {
    organization: {
      id: string
      adoptionContract: File | null
      logo: File | null
      qrCode: File | null
      name: string
      subscriptionActive?: boolean
    }
  }) => void
}

export const createOrganizationSlice: StateCreator<OrganizationSlice> = (
  set
) => ({
  currentOrganization: {
    organization: undefined,
  },
  setCurrentOrganization: ({ organization }) => {
    set({
      currentOrganization: {
        organization,
      },
    })
  },
})
