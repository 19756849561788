import FosterAdoptionCard from '../../../../components/fosters/cards/FosterAdoptionsCard'
import { useFosterFormContext } from '../../../../components/fosters/FosterFormContext'
import FosterTab from '../../../../components/fosters/FosterTab'
import { useAppStore } from '../../../../store/useAppStore'

const FosterAdoptionsTab = () => {
  const { control, formState } = useFosterFormContext()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  return (
    <FosterTab viewId="foster-adoptions-tab">
      <FosterAdoptionCard
        control={control}
        disabled={!currentOrganization?.subscriptionActive}
        formState={formState}
      />
    </FosterTab>
  )
}

export default FosterAdoptionsTab
