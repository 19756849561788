import { zodResolver } from '@hookform/resolvers/zod'
import { router } from 'expo-router'
import { useForm } from 'react-hook-form'
import { Button, Modal, Portal, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import FormFieldText from '../form/FormFieldText'

interface Props {
  open: boolean
  onClose: () => void
}

const newFosterFormSchema = z.object({
  name: z.string().min(3, 'Names must be at least 3 characters'),
})

type NewFosterForm = z.infer<typeof newFosterFormSchema>

const NewFoster = ({ open, onClose }: Props) => {
  const { styles } = useStyles(stylesheet)

  const currentOrganizationId =
    useAppStore.use.currentOrganization().organization?.id

  const newFosterMutation = trpc.foster.create.useMutation()

  const { control, formState, handleSubmit, reset } = useForm<NewFosterForm>({
    mode: 'onBlur',
    resolver: zodResolver(newFosterFormSchema),
    defaultValues: {
      name: '',
    },
  })

  if (!currentOrganizationId) {
    return null
  }

  const onSubmit = handleSubmit(async (data) => {
    const response = await newFosterMutation.mutateAsync({
      ...data,
      organizationId: currentOrganizationId,
    })

    router.push(`/fosters/${response.id}`)

    reset()
    onClose()
  })

  return (
    <Portal>
      <Modal
        contentContainerStyle={styles.root}
        onDismiss={onClose}
        visible={open}
      >
        <Text variant="titleSmall">
          Enter a name for your foster.{'\n'}
          <Text variant="labelSmall">(You can change this later)</Text>
        </Text>
        <FormFieldText
          autoFocus
          blurOnSubmit
          control={control}
          errors={formState.errors}
          fieldName="name"
          label="Name"
          required
        />
        <Button
          disabled={newFosterMutation.isLoading}
          loading={newFosterMutation.isLoading}
          mode="contained"
          onPress={onSubmit}
        >
          Add Foster
        </Button>
      </Modal>
    </Portal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    root: {
      backgroundColor: theme.colors.background,
      borderRadius: theme.tokens.containerBorderRadius,
      gap: theme.tokens.spacing[4],
      margin: 'auto',
      padding: theme.tokens.spacing[6],
      width: 300,
    },
  }
})

export default NewFoster
