import {
  Control,
  FieldErrors,
  FieldValues,
  FormState,
  Path,
} from 'react-hook-form'
import { DropdownProps } from 'react-native-element-dropdown/lib/typescript/components/Dropdown/model'
import { useAppStore } from '../../../../store/useAppStore'
import trpc from '../../../../utils/trpc'
import FormFieldSelect from '../../../form/FormFieldSelect'

type data = { label: string; value: string }

interface Props<C extends FieldValues, _E extends FieldErrors> {
  control: Control<C, any>
  disabled?: boolean
  formState: FormState<C>
  label?: string
  isLoading?: boolean
}

const FormFieldVolunteer = <C extends FieldValues, E extends FieldErrors>({
  control,
  disabled,
  formState,
  isLoading,
  ...props
}: Props<C, E> & Partial<DropdownProps<data>>) => {
  const currentOrganization = useAppStore.use.currentOrganization().organization

  const users = trpc.user.list.useQuery(
    {
      organizationId: currentOrganization?.id || '',
      includeDeactivated: true,
    },
    {
      enabled: !!currentOrganization,
    }
  )

  // Get the current form value for this field
  const currentUserId = control._formValues.userId

  const volunteerList =
    users.data
      ?.filter((user) => !user.deactivatedAt || user.id === currentUserId)
      .sort((a, b) => a.firstName.localeCompare(b.firstName))
      ?.map((user) => ({
        label: user.firstName + ' ' + user.lastName,
        value: user.id,
      })) || []

  return (
    <FormFieldSelect
      autoScroll
      control={control}
      data={volunteerList || []}
      disable={disabled}
      errors={formState.errors}
      fieldName={'userId' as Path<C>}
      isLoading={isLoading}
      label={props.label || 'Volunteer'}
      mode="modal"
      required={false}
      search={true}
      searchField="label"
      searchPlaceholder="Search for a volunteer"
      style={props.style}
    />
  )
}

export default FormFieldVolunteer
