import { useActionSheet } from '@expo/react-native-action-sheet'
import { Image, useImage } from 'expo-image'
import { createRef, useCallback, useState } from 'react'
import { Alert, findNodeHandle, View } from 'react-native'
import {
  ActivityIndicator,
  Icon,
  Text,
  TouchableRipple,
} from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { ApiRoot } from '../../../../constants'
import useFile from '../../../hooks/useFile'
import { useAppStore } from '../../../store/useAppStore'
import log from '../../../utils/datadog/log/log'
import { logError } from '../../../utils/log'

const MAX_HEIGHT = 180
const MAX_WIDTH = 140

interface Props {
  allowedFileTypes: (
    | 'application/pdf'
    | 'image/*'
    | 'image/jpeg'
    | 'image/png'
  )[]
  createdAt?: Date
  fileName?: string
  fileId: string
  mimeType?: string
  name: string
  thumbURL?: string | null
  onDelete: () => void
  onUploadComplete: (fileIds: string[]) => void
}

const File = ({
  allowedFileTypes,
  createdAt,
  fileId,
  fileName,
  mimeType,
  name,
  thumbURL,
  onDelete,
  onUploadComplete,
}: Props) => {
  const { styles } = useStyles(stylesheet)
  const { showActionSheetWithOptions } = useActionSheet()
  const [isDownloading, setIsDownloading] = useState(false)

  const accessToken = useAppStore.use.auth().accessToken

  const _anchorRef = createRef<any>()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const url = `${ApiRoot()}/${currentOrganization?.id}/${fileId}/download?size=400`

  const image = useImage(
    {
      uri: mimeType?.startsWith('image/') && url ? url : '',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Cache-Control': 'no-store, no-cache',
      },
    },
    {
      maxWidth: 100,
      onError(error) {
        console.warn('Loading failed:', error.message, thumbURL)
      },
    }
  )

  const imageHeight = image?.height || MAX_HEIGHT
  const imageWidth = image?.width || MAX_WIDTH

  const { openFile, pickFilesAndUpload } = useFile()

  const pickFiles = async () => {
    const fileIds = await pickFilesAndUpload({
      allowedFileTypes,
      multiple: false,
    })

    if (fileIds?.length) {
      onUploadComplete(fileIds)
    }
  }

  const open = useCallback(async () => {
    if (!currentOrganization?.id) {
      return
    }

    setIsDownloading(true)

    try {
      await openFile({
        fileName,
        fileId,
        mimeType,
      })
    } catch (error: any) {
      log.error('There was a problem opening the file')
      logError(error)
    }

    setIsDownloading(false)
  }, [currentOrganization?.id, fileId, fileName, mimeType, openFile])

  const onLongPress = () => {
    const options = ['Upload', 'Delete', 'Cancel']
    const uploadNew = 0
    const destructiveButtonIndex = 1
    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        anchor: findNodeHandle(_anchorRef.current) || undefined,
        useModal: false,
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (selectedIndex: number | undefined) => {
        switch (selectedIndex) {
          case uploadNew:
            pickFilesAndUpload({
              allowedFileTypes,
              multiple: true,
            })
            break
          case destructiveButtonIndex:
            Alert.alert(
              'Delete',
              'Are you sure you want to delete this document?',
              [
                {
                  text: 'Cancel',
                  onPress: () => console.log('Cancel Pressed'),
                  style: 'cancel',
                },
                {
                  isPreferred: false,
                  text: 'Delete',
                  onPress: () => onDelete(),
                  style: 'destructive',
                },
              ]
            )
            break

          case cancelButtonIndex:
            break
        }
      }
    )
  }

  let iconSource = 'file'
  if (allowedFileTypes.length === 1) {
    switch (allowedFileTypes[0]) {
      case 'application/pdf':
        iconSource = 'file-pdf-box'
        break
      case 'image/*':
        iconSource = 'image'
        break
    }
  }

  return (
    <View style={styles.root}>
      <TouchableRipple
        accessibilityRole="button"
        borderless
        disabled={isDownloading}
        onLongPress={onLongPress}
        onPress={mimeType ? open : pickFiles}
        ref={_anchorRef}
        style={[
          styles.fileContainer,
          mimeType?.startsWith('image') && {
            width: imageWidth < imageHeight ? MAX_WIDTH : MAX_HEIGHT,
            height: imageWidth < imageHeight ? MAX_HEIGHT : MAX_WIDTH,
          },
        ]}
      >
        <>
          {isDownloading && (
            <ActivityIndicator
              animating={true}
              style={{
                position: 'absolute',
                zIndex: 1,
                top:
                  (imageWidth < imageHeight ? MAX_WIDTH : MAX_WIDTH - 15) / 2 -
                  3,
              }}
            />
          )}
          {mimeType?.startsWith('image/') && url ? (
            <Image
              accessibilityIgnoresInvertColors={true}
              source={{
                uri: url,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }}
              style={{
                width: imageWidth <= imageHeight ? MAX_WIDTH : MAX_HEIGHT,
                height: imageWidth < imageHeight ? MAX_HEIGHT : MAX_WIDTH,
              }}
            />
          ) : (
            <Icon size={30} source={iconSource} />
          )}
        </>
      </TouchableRipple>
      <Text numberOfLines={1} style={styles.fileName}>
        {fileName || name}
      </Text>
      <Text style={styles.fileNameDate}>{createdAt?.toLocaleDateString()}</Text>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    fileContainer: {
      alignItems: 'center',
      borderColor: theme.colors.backdrop,
      borderRadius: 4,
      borderStyle: 'dashed',
      borderWidth: 1,
      height: MAX_HEIGHT,
      justifyContent: 'center',
      width: MAX_WIDTH,
    },
    fileName: {
      alignSelf: 'center',
    },
    fileNameDate: {
      color: theme.colors.subtitle,
    },
    root: {
      alignItems: 'center',
      margin: theme.tokens.spacing[2],
      width: MAX_WIDTH + 10,
    },
  }
})

export default File
