import { format } from 'date-fns'

const formatDate = (
  date: Date | null | undefined,
  dateFormat: string = 'M/dd/yy'
) => {
  if (!date) return date

  return format(date, dateFormat)
}

export default formatDate
