import { Control, FieldErrors, FieldValues, Path } from 'react-hook-form'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FormFieldText from '../../../form/FormFieldText'

interface Props<C extends FieldValues, E extends FieldErrors> {
  control: Control<C, any>
  disabled?: boolean
  errors?: E
  fieldName?: Path<C>
  label?: string
  isLoading?: boolean
  submit?: () => void
  weightDate?: Date | null
  style?: StyleProp<ViewStyle>
}

const FormFieldWeight = <C extends FieldValues, E extends FieldErrors>({
  control,
  disabled,
  errors,
  fieldName = 'weight' as Path<C>,
  isLoading,
  label = 'Weight',
  submit,
  weightDate,
  style,
  ...props
}: Props<C, E>) => {
  const { styles } = useStyles(stylesheet)
  const dateString = weightDate?.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })

  return (
    <View style={style}>
      <FormFieldText
        control={control}
        disabled={disabled}
        errors={errors}
        fieldName={fieldName}
        helperText={dateString && `Last Updated: ${dateString}`}
        isLoading={isLoading}
        keyboardType="numeric"
        label={label}
        required={false}
        {...props}
      />
      <Text style={styles.weightLabel}>lbs</Text>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  weightLabel: {
    color: theme.colors.subtitle,
    position: 'absolute',
    right: 12,
    top: 22,
  },
}))

export default FormFieldWeight
