import { useNavigation } from 'expo-router'
import { useEffect } from 'react'
import FosterPage from '../../../../components/fosters/FosterPage'
import FosterDetailHeaderContent from '../../../../components/navigation/header/FosterDetailHeaderContent'
import FosterService from '../../../../services/fosterService'

const headerRight = () => <FosterDetailHeaderContent />

const FosterDetail = () => {
  const navigation = useNavigation()

  const fosterName = FosterService.useCurrentFosterName()

  useEffect(() => {
    navigation.getParent()?.setOptions({
      title: fosterName || 'Add Foster',
      headerRight,
    })
  })

  return <FosterPage />
}

export default FosterDetail
