import FosterDocumentsCard from '../../../../components/fosters/cards/FosterDocumentsCard'
import FosterTab from '../../../../components/fosters/FosterTab'
import { useAppStore } from '../../../../store/useAppStore'

const FosterDocumentsTab = () => {
  const currentOrganization = useAppStore.use.currentOrganization().organization

  return (
    <FosterTab viewId="foster-documents-tab">
      <FosterDocumentsCard
        disabled={!currentOrganization?.subscriptionActive}
      />
    </FosterTab>
  )
}

export default FosterDocumentsTab
