import FontAwesome from '@expo/vector-icons/FontAwesome'
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs'
import { Tabs, useNavigation } from 'expo-router'
import { useEffect } from 'react'
import { UnistylesRuntime, useStyles } from 'react-native-unistyles'
import useNotificationObserver from '../../../hooks/useNotificationObserver'
import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'

const RootTabLayout = () => {
  useNotificationObserver()

  const { theme } = useStyles()

  const navigation = useNavigation()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const events = trpc.dashboard.events.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization,
    }
  ).data

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  })

  const screenOptions: BottomTabNavigationOptions = {
    tabBarActiveTintColor: theme.colors.primary,
    animation: 'shift',
    headerShown: false,
    tabBarPosition: 'bottom',
    tabBarStyle: {
      backgroundColor: theme.colors.background,
      borderTopColor: theme.colors.cardBorder,
    },
  }

  if (['lg', 'xl'].includes(UnistylesRuntime.breakpoint)) {
    screenOptions.tabBarPosition = 'left'
    screenOptions.tabBarVariant = 'material'
    screenOptions.tabBarStyle = {
      ...(typeof screenOptions.tabBarStyle === 'object'
        ? screenOptions.tabBarStyle
        : {}),
      minWidth: 70,
    }

    screenOptions.tabBarActiveBackgroundColor = theme.colors.primaryContainer
    screenOptions.tabBarActiveTintColor = theme.colors.onPrimaryContainer
  }

  return (
    <Tabs
      backBehavior="history"
      initialRouteName="dashboard"
      screenOptions={screenOptions}
    >
      <Tabs.Screen
        name="dashboard"
        options={{
          title: 'Dashboard',
          tabBarIcon: ({ color }) => (
            <FontAwesome color={color} name="home" size={28} />
          ),
          tabBarBadge: events?.[0]?.overdueCount || undefined,
          tabBarButtonTestID: 'dashboard-tab',
          tabBarAccessibilityLabel: 'Dashboard Tab',
        }}
      />
      <Tabs.Screen
        name="(fosters)"
        options={{
          lazy: false,
          title: 'Fosters',
          tabBarIcon: ({ color }) => (
            <FontAwesome color={color} name="paw" size={28} />
          ),
          tabBarButtonTestID: 'fosters-tab',
          tabBarAccessibilityLabel: 'Fosters Tab',
        }}
      />

      <Tabs.Screen name="index" redirect />
    </Tabs>
  )
}

export default RootTabLayout
