import { useCallback, useState } from 'react'
import { Control, FormState, useFieldArray } from 'react-hook-form'
import { Alert, View } from 'react-native'
import {
  Button,
  Icon,
  IconButton,
  Text,
  TouchableRipple,
  useTheme,
} from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import { uuidv7 } from 'uuidv7'
import MedicalInfoType from '../../../../enums/medicalInfoType'
import Species from '../../../../enums/species'
import FosterService from '../../../../services/fosterService'
import OrganizationService from '../../../../services/organizationService'
import { useAppStore } from '../../../../store/useAppStore'
import sharedStyles from '../../../../styles/styles'
import { Theme } from '../../../../styles/theme'
import formatDate from '../../../../utils/formatDate'
import trpc from '../../../../utils/trpc'
import FormFieldDate from '../../../form/FormFieldDate'
import FormFieldText from '../../../form/FormFieldText'
import FormFieldOrganizationVaccines from '../../../organization/cards/formFields/FormFieldOrganizationVaccines'
import InfoText from '../../../shared/InfoText'
import { FosterForm } from '../../fosterFormSchema'
import FosterVaccinationItem from './FosterVaccinationItem'

interface Props {
  control: Control<FosterForm, any>
  disabled: boolean
  formState: FormState<FosterForm>
}

const FosterVaccinations = ({ control, disabled, formState }: Props) => {
  const { styles } = useStyles(stylesheet)
  const theme = useTheme<Theme>()

  const [expandedIndex, setExpandedIndex] = useState<number>(-1)

  const breakpoint = UnistylesRuntime.breakpoint
  const mdAndDown = ['xs', 'sm', 'md'].includes(breakpoint)

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, refreshFoster, isLoading } = FosterService.useFoster(fosterId)

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const vaccinationMedicalInfos = OrganizationService.useMedicalInfo(
    MedicalInfoType.Vaccination,
    foster?.birthDate,
    (foster?.species as Species) || undefined
  )

  const processVaccinationsMutation =
    trpc.foster.processVaccinations.useMutation()

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'vaccinations',
    shouldUnregister: false,
    keyName: 'key',
  })

  const handleRemove = useCallback(
    (index: number) => {
      Alert.alert(
        'Delete',
        'Are you sure you want to delete this vaccination?',
        [
          {
            text: 'Cancel',
            onPress: () => console.log('Cancel Pressed'),
            style: 'cancel',
          },
          {
            isPreferred: false,
            text: 'Delete',
            onPress: () => remove(index),
            style: 'destructive',
          },
        ]
      )
    },
    [remove]
  )

  const handleAppend = useCallback(() => {
    append({
      fosterId: fosterId,
      id: uuidv7(),
      vaccineId: '',
      givenBy: null,
      dueDate: null,
      name: null,
    })

    setExpandedIndex(fields.length)
  }, [append, fields.length, fosterId])

  return (
    <View style={styles.root}>
      <InfoText text={vaccinationMedicalInfos?.[0]?.text} />
      {fields.map((field, index) => {
        const vaccination = foster?.vaccinations?.find((v) => v.id === field.id)

        return (
          <View
            key={`${field.key}-vaccinations`}
            style={styles.vaccinationItemContainer}
          >
            <View style={styles.statusIconContainer}>
              {vaccination?.givenDate ? (
                <Icon color={'green'} size={24} source="check" />
              ) : (
                <Icon color={theme.colors.subtitle} size={24} source="needle" />
              )}
            </View>
            <View style={styles.vaccinationFormFieldsContainer}>
              {mdAndDown && (
                <View>
                  <TouchableRipple
                    accessibilityRole="button"
                    borderless
                    onPress={() => {
                      setExpandedIndex(index === expandedIndex ? -1 : index)
                    }}
                    style={styles.pressableContainer}
                  >
                    <View style={styles.mobileVaccinationItemContainer}>
                      <Text>
                        {vaccination?.name}{' '}
                        {vaccination?.givenDate &&
                          `given ${formatDate(vaccination.givenDate)}`}
                        {vaccination?.dueDate &&
                          !vaccination.givenDate &&
                          `due ${formatDate(vaccination.dueDate)}`}
                      </Text>
                      {vaccination?.givenBy && (
                        <Text style={styles.clinicTitle}>
                          {vaccination?.givenBy}
                        </Text>
                      )}
                    </View>
                  </TouchableRipple>
                  <FosterVaccinationItem
                    control={control}
                    disabled={disabled}
                    formState={formState}
                    index={index}
                    open={expandedIndex === index}
                    vaccination={vaccination}
                  />
                  {expandedIndex === index && (
                    <TouchableRipple
                      accessibilityRole="button"
                      borderless
                      onPress={() => setExpandedIndex(-1)}
                      style={styles.vaccinationCollapseContainer}
                    >
                      <Icon size={32} source={'chevron-up'} />
                    </TouchableRipple>
                  )}
                </View>
              )}
              {!mdAndDown && (
                <>
                  <FormFieldOrganizationVaccines
                    control={control}
                    disabled={disabled}
                    fieldName={`vaccinations.${index}.vaccineId`}
                    formState={formState}
                    label="Vaccine"
                    style={styles.formField}
                  />
                  {!vaccination?.givenDate && (
                    <FormFieldDate
                      control={control}
                      disabled={disabled}
                      errors={formState.errors}
                      fieldName={`vaccinations.${index}.dueDate`}
                      label="Due On"
                      required={false}
                      style={styles.formField}
                    />
                  )}
                  <FormFieldDate
                    control={control}
                    disabled={disabled}
                    errors={formState.errors}
                    fieldName={`vaccinations.${index}.givenDate`}
                    label="Given On"
                    required={false}
                    style={styles.formField}
                  />
                  <FormFieldText
                    control={control}
                    disabled={disabled}
                    errors={formState.errors}
                    fieldName={`vaccinations.${index}.givenBy`}
                    label="Given By"
                    required={true}
                    style={styles.givenBy}
                  />
                </>
              )}
            </View>
            <View style={styles.deleteIconContainer}>
              <IconButton
                disabled={disabled}
                icon={'trash-can'}
                onPress={() => handleRemove(index)}
              />
            </View>
          </View>
        )
      })}
      <View style={styles.buttonRow}>
        <Button
          disabled={
            disabled ||
            processVaccinationsMutation.isLoading ||
            foster?.birthDate === null
          }
          loading={processVaccinationsMutation.isLoading || isLoading}
          mode="contained"
          onPress={() => {
            processVaccinationsMutation.mutate(
              {
                id: fosterId,
                organizationId: currentOrganization?.id || '',
              },
              {
                onSuccess: async () => {
                  await refreshFoster()
                },
              }
            )
          }}
          style={[styles.formField, styles.scheduleButton]}
          testID={'process-vaccinations'}
        >
          {!fields.length ? 'Generate Schedule' : 'Update Schedule'}
        </Button>
        <Button
          disabled={disabled}
          onPress={handleAppend}
          style={styles.formField}
        >
          Manually Add Vaccination
        </Button>
      </View>
      <View>
        {foster?.birthDate === null && (
          <Text style={styles.birthDateRequired} variant="bodySmall">
            Please enter a birth date to generate a vaccination schedule.
          </Text>
        )}
      </View>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    birthDateRequired: {
      marginBottom: theme.tokens.spacing[2],
      marginHorizontal: {
        xs: 'auto',
        md: theme.tokens.spacing[2],
      },
      marginTop: {
        xs: theme.tokens.spacing[3],
        md: theme.tokens.spacing[5],
      },
      textAlign: {
        xs: 'center',
        md: 'left',
      },
      width: '80%',
    },
    buttonRow: sharedStyles(theme).buttonRow,
    clinicTitle: {
      color: theme.colors.subtitle,
      paddingTop: theme.tokens.spacing[1],
    },
    deleteIconContainer: {
      position: {
        xs: 'absolute',
        lg: 'relative',
      },
      right: 0,
      top: 0,
    },
    formField: {
      flex: 0.3,
    },
    givenBy: {
      flex: 0.4,
    },
    mobileVaccinationItemContainer: {
      flex: 1,
      justifyContent: 'center',
      marginLeft: theme.tokens.spacing[8],
    },
    pressableContainer: {
      borderRadius: theme.tokens.containerBorderRadius,
      flex: 1,
      minHeight: 52,
      paddingHorizontal: theme.tokens.spacing[1],
      paddingVertical: theme.tokens.spacing[3],
    },
    root: {
      flex: 1,
    },
    scheduleButton: { marginTop: theme.tokens.spacing[3] },
    statusIconContainer: {
      left: 0,
      paddingHorizontal: 12,
      paddingVertical: 14,
      position: {
        xs: 'absolute',
        lg: 'relative',
      },
      top: 0,
    },
    vaccinationCollapseContainer: {
      alignItems: 'center',
      borderRadius: theme.tokens.containerBorderRadius,
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: theme.tokens.spacing[3],
      padding: theme.tokens.spacing[2],
    },
    vaccinationFormFieldsContainer: {
      flexDirection: {
        xs: 'column',
        lg: 'row',
      },
      flex: 1,
      gap: 4,
      height: '100%',
    },
    vaccinationItemContainer: {
      alignItems: 'center',
      borderColor: theme.colors.outline,
      borderRadius: theme.tokens.containerBorderRadius,
      borderWidth: {
        xs: 1,
        lg: 0,
      },
      flexDirection: 'row',
      flex: 1,
      gap: 4,
      marginTop: theme.tokens.spacing[3],
    },
  }
})

export default FosterVaccinations
