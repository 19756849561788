import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import { Platform } from 'react-native'
import ReactNativeBlobUtil from 'react-native-blob-util'
import DocumentScanner, {
  ResponseType,
  ScanDocumentResponseStatus,
} from 'react-native-document-scanner-plugin'
import { ApiRoot } from '../../constants'
import Snackbar from '../components/snackbar/Snackbar'
import { useAppStore } from '../store/useAppStore'
import log from '../utils/datadog/log/log'

const useFile = () => {
  const currentOrganization = useAppStore.use.currentOrganization().organization

  const accessToken = useAppStore.use.auth().accessToken
  const uploadUrl = `${ApiRoot()}/${currentOrganization?.id}/fileUpload`

  const scanFiles = async () => {
    const result = await DocumentScanner.scanDocument({
      croppedImageQuality: 100,
      responseType: ResponseType.ImageFilePath,
    })

    if (result.status === ScanDocumentResponseStatus.Success) {
      if (!result.scannedImages) {
        Snackbar.error(
          'There was an error scanning the documents. Please try again.'
        )
        return
      }

      return result.scannedImages
    }
  }

  const createUploadTasks = ({
    files,
    progressCallback,
  }: {
    files: { fileName: string; fileUri: string }[]
    progressCallback?: (
      progress: FileSystem.UploadProgressData,
      fileIndex: number
    ) => void
  }) => {
    const uploadTasks = files.map((file, index) =>
      FileSystem.createUploadTask(
        uploadUrl,
        file.fileUri,
        {
          uploadType: FileSystem.FileSystemUploadType.MULTIPART,
          // for some reason either this expo module, or the fastify multi-part handler, are overwriting the filename with a random UUID. we want to keep the original filename
          // so we're passing it as an extra field on the body
          parameters: {
            fileName: file.fileName,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        progressCallback
          ? (progress: FileSystem.UploadProgressData) =>
              progressCallback(progress, index)
          : undefined
      )
    )

    return uploadTasks
  }

  const pickFilesAndUpload = async ({
    allowedFileTypes,
    multiple,
  }: {
    allowedFileTypes: string[]
    multiple: boolean
  }) => {
    const pickResult = await DocumentPicker.getDocumentAsync({
      type: allowedFileTypes,
      multiple,
    })

    if (!pickResult.assets?.length) {
      return
    }

    const files = pickResult.assets.map((asset) => ({
      fileName: asset.name,
      fileUri: asset.uri,
    }))

    return uploadFiles(files)
  }

  const uploadFiles = async (
    files: { fileName: string; fileUri: string }[]
  ) => {
    const uploadResults = await Promise.all(
      files.map(async (file) => {
        return FileSystem.uploadAsync(uploadUrl, file.fileUri, {
          uploadType: FileSystem.FileSystemUploadType.MULTIPART,
          // for some reason either this expo module, or the fastify multi-part handler, are overwriting the filename with a random UUID. we want to keep the original filename
          // so we're passing it as an extra field on the body
          parameters: {
            fileName: file.fileName,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      })
    )

    const fileIds = uploadResults.map((result) => {
      const json = JSON.parse(result.body) as { fileId: string }
      return json.fileId
    })

    return fileIds
  }

  const openFile = async ({
    fileName,
    fileId,
    mimeType,
  }: {
    fileName?: string | null
    fileId?: string | null
    mimeType?: string | null
  }) => {
    const fileURL = `${ApiRoot()}/${currentOrganization?.id}/${fileId}/download`

    if (!fileURL || !mimeType) {
      log.error(
        'There was a problem opening the file. fileURL was undefined or null'
      )

      Snackbar.error('There was a problem opening the file.')
      return
    }

    if (Platform.OS === 'web') {
      window.open(fileURL, '_blank')
    } else {
      const filePath =
        ReactNativeBlobUtil.fs.dirs.CacheDir + `/${fileId}/${fileName}`

      if (!(await ReactNativeBlobUtil.fs.exists(filePath))) {
        const downloadResponse = await ReactNativeBlobUtil.fetch(
          'GET',
          fileURL,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        )

        await ReactNativeBlobUtil.fs.writeFile(
          filePath,
          downloadResponse.data,
          'base64'
        )
      }

      if (Platform.OS === 'android') {
        await ReactNativeBlobUtil.android.actionViewIntent(filePath, mimeType)
      } else if (Platform.OS === 'ios') {
        ReactNativeBlobUtil.ios.openDocument(filePath)
      }
    }
  }

  return {
    openFile,
    pickFilesAndUpload,
    createUploadTasks,
    scanFiles,
    uploadFiles,
  }
}

export default useFile
