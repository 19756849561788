import { Link } from 'expo-router'
import { ReactNode, useCallback, useState } from 'react'
import { View } from 'react-native'
import { RefreshControl } from 'react-native-gesture-handler'
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { KeyboardOffset } from '../../../constants'
import useUserRoles from '../../hooks/useUserRoles'
import { useAppStore } from '../../store/useAppStore'
import SettingsModal from '../navigation/SettingsModal'

interface Props {
  children: ReactNode
  onRefresh?: () => Promise<void>
  viewId: string
  requiresSubscription?: boolean
}

const Page = ({
  children,
  onRefresh,
  viewId,
  requiresSubscription = true,
}: Props) => {
  const { styles, theme } = useStyles(stylesheet)
  const [refreshing, setRefreshing] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const hideModal = () => setModalVisible(false)

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const hasActiveSubscription =
    currentOrganization?.subscriptionActive !== false

  const { isUserAdmin } = useUserRoles()

  const doRefresh = useCallback(() => {
    setRefreshing(true)
    onRefresh?.()
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false))
  }, [onRefresh])

  return (
    <KeyboardAwareScrollView
      bottomOffset={KeyboardOffset}
      contentContainerStyle={styles.container}
      contentInsetAdjustmentBehavior="automatic"
      nativeID={viewId}
      refreshControl={
        onRefresh ? (
          <RefreshControl onRefresh={doRefresh} refreshing={refreshing} />
        ) : undefined
      }
    >
      {requiresSubscription && !hasActiveSubscription && (
        <>
          <View style={styles.banner} testID="deactivated-banner">
            <Text style={{ marginBottom: 8 }} variant="titleLarge">
              Billing Issue
            </Text>
            <Text style={{ marginBottom: 8 }} variant="titleMedium">
              {currentOrganization.name} is read-only
            </Text>
            <Text>
              There's been a billing issue for {currentOrganization.name}.
              Contact an organization administrator to resolve this issue.
            </Text>
            {isUserAdmin && (
              <Link
                asChild
                href={`/organization/${currentOrganization.id}/settings`}
              >
                <Button
                  style={styles.subscriptionSettingsButton}
                  textColor={theme.colors.error}
                >
                  Settings
                </Button>
              </Link>
            )}
          </View>
          <SettingsModal hideModal={hideModal} visible={modalVisible} />
        </>
      )}
      {children}
    </KeyboardAwareScrollView>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  banner: {
    backgroundColor: theme.colors.errorContainer,
    borderColor: theme.colors.error,
    borderRadius: theme.tokens.containerBorderRadius,
    borderWidth: 1,
    margin: 20,
    paddingBottom: theme.tokens.spacing[3],
    paddingLeft: theme.tokens.spacing[4],
    paddingRight: theme.tokens.spacing[4],
    paddingTop: theme.tokens.spacing[3],
  },
  container: {
    flexDirection: 'column',
    gap: theme.tokens.spacing[4],
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
  subscriptionSettingsButton: {
    marginTop: theme.tokens.spacing[3],
    width: 100,
  },
}))

export default Page
