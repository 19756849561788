import { Avatar } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import InitialsAvatar from './InitialsAvatar'

const OrganizationAvatar = ({
  logo,
  name,
  size,
}: {
  logo: {
    url: string | null
  } | null
  name: string
  size: number
}) => {
  const { styles } = useStyles(stylesheet)

  if (!logo?.url) {
    return <InitialsAvatar size={size} string={name} style={styles.avatar} />
  } else {
    return (
      <Avatar.Image
        size={size}
        source={{
          uri: logo.url,
        }}
        style={styles.avatar}
      />
    )
  }
}

const stylesheet = createStyleSheet(() => ({
  avatar: {
    backgroundColor: 'white',
    marginRight: 10,
  },
}))

export default OrganizationAvatar
