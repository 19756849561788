import Role from '../enums/role'
import { useAppStore } from '../store/useAppStore'
import trpc from '../utils/trpc'

const useUserRoles = () => {
  const currentOrganization = useAppStore.use.currentOrganization().organization
  const currentUser = trpc.user.current.useQuery({
    organizationId: currentOrganization?.id,
  }).data

  const isUserAdmin = currentUser?.roles.some(
    (userRole) => userRole === Role.Admin
  )

  return { userRoles: currentUser?.roles, isUserAdmin }
}

export default useUserRoles
