import { StateCreator } from 'zustand'

export interface PushTokenSlice {
  pushToken: {
    token?: string
    lastSyncedAt?: Date
  }
  setPushToken: (pushToken: { token?: string; lastSyncedAt?: Date }) => void
}

export const createPushTokenSlice: StateCreator<PushTokenSlice> = (set) => ({
  pushToken: {
    token: undefined,
    lastSyncedAt: undefined,
  },
  setPushToken: ({ token, lastSyncedAt }) => {
    set({
      pushToken: {
        token,
        lastSyncedAt,
      },
    })
  },
})
