import {
  BottomSheetTextInput,
  useBottomSheetInternal,
} from '@gorhom/bottom-sheet'
import { BottomSheetInternalContextType } from '@gorhom/bottom-sheet/lib/typescript/contexts/internal'
import { useEffect, useState } from 'react'
import {
  TextInput as ActualTextInput,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  Text,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native'
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Outline from './Outline'

const BLUR_ANIMATION_DURATION = 180
const FOCUS_ANIMATION_DURATION = 150

const TextInputLabel = Animated.createAnimatedComponent(Text)

const TextInput = (
  props: TextInputProps & {
    label?: string
    contentStyle?: StyleProp<ViewStyle>
    outlineStyle?: StyleProp<ViewStyle>
  }
) => {
  const { styles, theme } = useStyles(stylesheet)

  const labelPositionY = useSharedValue(0)
  const labelPositionX = useSharedValue(0)

  const [focused, setFocused] = useState(false)

  const focusedAnimation = () => {
    labelPositionY.value = withTiming(-25, {
      duration: FOCUS_ANIMATION_DURATION,
    })
    labelPositionX.value = withTiming(-5, {
      duration: FOCUS_ANIMATION_DURATION,
    })
  }

  const onFocus = () => {
    setFocused(true)
    focusedAnimation()
  }

  useEffect(() => {
    if (props?.value?.length) {
      labelPositionY.value = -25
      labelPositionX.value = -5
    }
  }, [labelPositionX, labelPositionY, props?.value])

  const onBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocused(false)

    props.onBlur?.(e)

    if (props?.value?.length) {
      return
    }

    labelPositionY.value = withTiming(0, { duration: BLUR_ANIMATION_DURATION })
    labelPositionX.value = withTiming(0, { duration: BLUR_ANIMATION_DURATION })
  }

  const animatedLabelStyle = useAnimatedStyle(() => {
    return {
      left: 5,
      zIndex: 1,
      transform: [
        {
          translateY: labelPositionY.value,
        },
        {
          translateX: labelPositionX.value,
        },
        {
          scale: interpolate(
            labelPositionY.value,
            [-20, 0],
            [0.8, 1],
            Extrapolate.CLAMP
          ),
        },
      ],
    }
  })

  const bottomSheet: BottomSheetInternalContextType | null =
    useBottomSheetInternal(true)

  const FinalTextInput = bottomSheet ? BottomSheetTextInput : ActualTextInput

  return (
    <View style={[styles.inputContainer, props.contentStyle]}>
      <Outline
        activeColor={theme.colors.primary}
        backgroundColor={theme.colors.surface}
        hasActiveOutline={focused}
        label={!!props.label}
        outlineColor={theme.colors.outline}
        roundness={4}
      />
      <Animated.View
        pointerEvents="none"
        style={[styles.labelContainer, animatedLabelStyle]}
      >
        <TextInputLabel
          style={[
            styles.labelText,
            focused
              ? { color: theme.colors.primary }
              : { color: theme.colors.onSurfaceVariant },
            props.editable === false && styles.disabled,
          ]}
        >
          {props.label}
        </TextInputLabel>
      </Animated.View>
      <FinalTextInput
        {...props}
        onBlur={onBlur}
        onFocus={onFocus}
        style={[
          props.multiline && styles.multiline,
          props.style,
          styles.textInput,
          props.editable === false && styles.disabled,
        ]}
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    disabled: {
      color: theme.colors.onSurfaceDisabled,
    },
    inputContainer: {
      minHeight: 56,
      paddingTop: 6,
    },
    labelContainer: {
      background: 'none',
      left: 20,
      position: 'absolute',
      top: 20,
    },
    labelText: {
      backgroundColor: theme.colors.surface,
      color: theme.colors.onSurfaceVariant,
      fontSize: 16,
      paddingHorizontal: 8,
    },
    multiline: {
      paddingTop: 10,
    },
    textInput: {
      borderRadius: theme.tokens.containerBorderRadius,
      color: theme.colors.onSurface,
      flex: Platform.OS === 'web' ? 1 : 0,
      fontSize: 16,
      justifyContent: 'center',
      minHeight: 48,
      outline: 'none',
      paddingLeft: 16,
    },
  }
})

export default TextInput
