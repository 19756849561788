import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

const InfoText = ({ text }: { text?: string }) => {
  const { styles } = useStyles(stylesheet)
  if (!text) return null

  return <Text style={[styles.root]}>{text}</Text>
}

const stylesheet = createStyleSheet((theme) => ({
  root: {
    marginBottom: theme.tokens.spacing[3],
    marginTop: theme.tokens.spacing[1],
  },
}))

export default InfoText
