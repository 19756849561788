import { router } from 'expo-router'
import { useState } from 'react'
import { Platform } from 'react-native'
import { IconButton } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../../store/useAppStore'
import GridFilterSheet from '../../filtering/GridFilterSheet'
import UserAvatarButton from '../UserAvatarButton'

const FosterGridHeaderContent = () => {
  const { theme } = useStyles()

  const [filterSheetOpen, setFilterSheetOpen] = useState(false)

  const basicFilters = useAppStore.use.basicFilters()
  const advancedFilters = useAppStore.use.advancedFilters()

  const dirtyFilters =
    (advancedFilters.isActive && advancedFilters.isDirty) ||
    (basicFilters.isActive && basicFilters.isDirty)

  return (
    <>
      {Platform.OS !== 'web' && (
        <IconButton
          icon="magnify"
          onPress={() => {
            router.push(`/search`)
          }}
          testID="search-icon-button"
        />
      )}
      <IconButton
        icon={dirtyFilters ? 'filter-check' : 'filter-outline'}
        iconColor={dirtyFilters ? theme.colors.primary : undefined}
        onPress={() => {
          setFilterSheetOpen(true)
        }}
        testID="filter-button"
      />
      <GridFilterSheet
        onClose={() => {
          setFilterSheetOpen(false)
        }}
        open={filterSheetOpen}
      />
      <UserAvatarButton />
    </>
  )
}

export default FosterGridHeaderContent
