import { useEffect } from 'react'
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import FormFieldSelect from '../../../form/FormFieldSelect'
import {
  AdvancedFilterField,
  AdvancedFilterFormValues,
  AdvancedFilterOperator,
  AdvancedFilterOperators,
  AdvancedFilterType,
} from '../AdvancedFilterTypes'

const AdvancedFilterOperatorField = ({
  index,
  control,
  selectedField,
  selectedOperator,
  setValue,
  dropdownStyle,
}: {
  index: number
  control: Control<AdvancedFilterFormValues>
  errors: FieldErrors<AdvancedFilterFormValues>
  selectedField: AdvancedFilterField | undefined
  selectedOperator: AdvancedFilterOperator | string
  setValue: UseFormSetValue<AdvancedFilterFormValues>
  dropdownStyle: StyleProp<ViewStyle>
  textViewStyle: StyleProp<TextStyle>
}) => {
  const isAdoptionDate = selectedField?.field === 'adoptions.adoptionDate'
  const isAdoptedByName = selectedField?.field === 'adoptions.adoptedByName'

  useEffect(() => {
    if (
      selectedField &&
      !selectedOperator &&
      !isAdoptionDate &&
      !isAdoptedByName
    ) {
      switch (selectedField.type) {
        case AdvancedFilterType.Date:
          setValue(`filters.${index}.operator`, AdvancedFilterOperator.Equals)
          break
        case AdvancedFilterType.String:
          setValue(`filters.${index}.operator`, AdvancedFilterOperator.Contains)
          break
        case AdvancedFilterType.Boolean:
        case AdvancedFilterType.Enum:
          setValue(`filters.${index}.operator`, AdvancedFilterOperator.Equals)
          break
      }
    }

    if (isAdoptionDate) {
      setValue(`filters.${index}.operator`, AdvancedFilterOperator.LessThan)
    }

    if (isAdoptedByName) {
      setValue(`filters.${index}.operator`, AdvancedFilterOperator.Contains)
    }
  }, [
    index,
    isAdoptionDate,
    isAdoptedByName,
    selectedField,
    selectedField?.type,
    selectedOperator,
    setValue,
  ])

  let operatorOptions =
    AdvancedFilterOperators[selectedField?.type || AdvancedFilterType.String]

  if (isAdoptionDate) {
    operatorOptions = operatorOptions.filter(
      (op) =>
        op.value !== AdvancedFilterOperator.NotEquals &&
        op.value !== AdvancedFilterOperator.Equals
    )
  }

  if (isAdoptedByName) {
    operatorOptions = operatorOptions.filter(
      (op) =>
        op.value !== AdvancedFilterOperator.NotEquals &&
        op.value !== AdvancedFilterOperator.Equals
    )
  }

  return (
    <FormFieldSelect
      control={control}
      data={operatorOptions}
      fieldName={`filters.${index}.operator`}
      label="Select Operator"
      required
      style={dropdownStyle}
    />
  )
}

export default AdvancedFilterOperatorField
