export enum AdvancedFilterType {
  Date = 'date',
  String = 'string',
  Boolean = 'boolean',
  Enum = 'enum',
}

export enum AdvancedFilterOperator {
  GreaterThan = 'gt',
  LessThan = 'lt',
  Equals = 'eq',
  NotEquals = 'ne',
  Contains = 'ctn',
  DoesNotContain = 'not_ctn',
}

export const AdvancedFilterOperators = {
  [AdvancedFilterType.Date]: [
    { label: 'is after', value: AdvancedFilterOperator.GreaterThan },
    { label: 'is before', value: AdvancedFilterOperator.LessThan },
    { label: 'exists', value: AdvancedFilterOperator.NotEquals },
    { label: 'does not exist', value: AdvancedFilterOperator.Equals },
  ],
  [AdvancedFilterType.Boolean]: [
    { label: 'is true', value: AdvancedFilterOperator.Equals },
    { label: 'is false', value: AdvancedFilterOperator.NotEquals },
  ],
  [AdvancedFilterType.String]: [
    { label: 'contains', value: AdvancedFilterOperator.Contains },
    { label: 'does not contain', value: AdvancedFilterOperator.DoesNotContain },
    { label: 'exists', value: AdvancedFilterOperator.NotEquals },
    { label: 'does not exist', value: AdvancedFilterOperator.Equals },
  ],
  [AdvancedFilterType.Enum]: [
    { label: 'is', value: AdvancedFilterOperator.Equals },
    { label: 'is not', value: AdvancedFilterOperator.NotEquals },
  ],
}

export const AdvancedFilterSchema = [
  { title: 'Adopted', field: 'isAdopted', type: AdvancedFilterType.Boolean },
  {
    title: 'Adopted Name',
    field: 'adoptions.adoptedName',
    type: AdvancedFilterType.String,
  },
  {
    title: 'Adoption Date',
    field: 'adoptions.adoptionDate',
    type: AdvancedFilterType.Date,
  },
  {
    title: 'Adoption Return Date',
    field: 'adoptions.returnDate',
    type: AdvancedFilterType.Date,
  },
  {
    title: 'Adopter Email',
    field: 'adoptions.adoptedByEmail',
    type: AdvancedFilterType.String,
  },
  {
    title: 'Adopter Name',
    field: 'adoptions.adoptedByName',
    type: AdvancedFilterType.String,
  },
  { title: 'Birth Date', field: 'birthDate', type: AdvancedFilterType.Date },
  { title: 'Breed', field: 'breed', type: AdvancedFilterType.String },
  {
    title: 'Combo Testing Completed',
    field: 'comboTestingCompletedDate',
    type: AdvancedFilterType.Date,
  },
  {
    title: 'Deceased Date',
    field: 'deceasedDate',
    type: AdvancedFilterType.Date,
  },
  {
    title: 'Spayed/Neutered Before Intake',
    field: 'fixedBeforeIntake',
    type: AdvancedFilterType.Boolean,
  },
  {
    title: 'Spayed/Neutered On',
    field: 'fixedOnDate',
    type: AdvancedFilterType.Date,
  },
  {
    title: 'Flea/Tick Prev. Due',
    field: 'nextFleaTickMedDue',
    type: AdvancedFilterType.Date,
  },
  {
    title: 'Gender',
    field: 'gender',
    type: AdvancedFilterType.Enum,
  },
  {
    title: 'Heartworm Prev. Due',
    field: 'nextHeartwormMedDue',
    type: AdvancedFilterType.Date,
  },
  {
    title: 'Heartworm Positive',
    field: 'heartwormPositive',
    type: AdvancedFilterType.Boolean,
  },
  {
    title: 'Heartworm Testing Complete',
    field: 'heartwormTestingCompletedDate',
    type: AdvancedFilterType.Date,
  },
  { title: 'Hospice', field: 'isInHospice', type: AdvancedFilterType.Boolean },
  { title: 'Intake Date', field: 'intakeDate', type: AdvancedFilterType.Date },
  {
    title: 'Microchip Number',
    field: 'microchipId',
    type: AdvancedFilterType.String,
  },
  { title: 'Name', field: 'name', type: AdvancedFilterType.String },
  {
    title: 'Ready For Adoption',
    field: 'readyForAdoption',
    type: AdvancedFilterType.Boolean,
  },
  {
    title: 'Species',
    field: 'species',
    type: AdvancedFilterType.Enum,
  },
  { title: 'Updated At', field: 'updatedAt', type: AdvancedFilterType.Date },
  { title: 'Updated By', field: 'updatedBy', type: AdvancedFilterType.String },
  {
    title: 'Volunteer Name',
    field: 'userFullName',
    type: AdvancedFilterType.String,
  },
]

export interface AdvancedFilterField {
  title: string
  field: string
  type: AdvancedFilterType
}

export interface AdvancedFilterItem {
  field: string
  operator: string
  value: string | boolean | Date | null
}

export interface AdvancedFilterFormValues {
  filters: AdvancedFilterItem[]
}
