import { useLocalSearchParams } from 'expo-router'
import OrganizationEditMedicalInfo from '../../../../../components/organization/cards/forms/OrganizationEditMedicalInfo'

const MedicalInfo = () => {
  const { medicalInfoId } = useLocalSearchParams<{ medicalInfoId: 'string' }>()

  return <OrganizationEditMedicalInfo medicalInfoId={medicalInfoId} />
}

export default MedicalInfo
