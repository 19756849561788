import { router } from 'expo-router'
import { useState } from 'react'
import { FAB } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'
import useFile from '../../hooks/useFile'
import useFosterOwner from '../../hooks/useFosterOwner'
import FosterService from '../../services/fosterService'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import NewAdoptionAction from './actions/NewAdoptionAction'
import ReturnAdoptionAction from './actions/ReturnAdoptionAction'
import useDeleteAction from './actions/useDeleteAction'
import useMarkDeceased from './actions/useMarkDeceased'
import usePrintPdfAction from './actions/usePrintPdfAction'

type FosterActionButtonProps = {
  currentOrganizationId: string
  fosterId: string
}

const FosterActionButton = ({
  currentOrganizationId,
  fosterId,
}: FosterActionButtonProps) => {
  const { theme } = useStyles()

  const { foster } = FosterService.useFoster(fosterId)

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const [state, setState] = useState({ open: false })
  const [newAdoptionRecordOpen, setNewAdoptionRecordOpen] = useState(false)
  const [returnAdoptionOpen, setReturnAdoptionOpen] = useState(false)
  const onStateChange = ({ open }: { open: boolean }) => setState({ open })

  const fosterOwner = useFosterOwner(fosterId)

  const pdfAction = usePrintPdfAction(fosterId, currentOrganizationId)
  const { deleteFoster } = useDeleteAction()
  const { markDeceased } = useMarkDeceased()

  const copyFosterMutation = trpc.foster.copy.useMutation()

  const { openFile, scanFiles } = useFile()

  const { open } = state

  return (
    <>
      <FAB.Group
        actions={[
          ...(fosterOwner
            ? [
                {
                  icon: 'delete',
                  label: 'Delete',
                  color: theme.colors.error,
                  onPress: () => {
                    deleteFoster()
                  },
                  testID: 'delete-action',
                },
                ...(!foster?.deceasedDate
                  ? [
                      {
                        icon: 'grave-stone',
                        label: 'Mark Deceased',
                        onPress: () => {
                          markDeceased()
                        },
                        testID: 'mark-deceased-action',
                      },
                    ]
                  : []),
              ]
            : []),
          {
            icon: 'content-copy',
            label: 'Copy Foster',
            onPress: async () => {
              await copyFosterMutation.mutateAsync(
                {
                  id: fosterId,
                  organizationId: currentOrganizationId,
                },
                {
                  onSuccess: (newFoster) => {
                    router.back()
                    router.push(`/fosters/${newFoster.id}`)
                  },
                }
              )
            },
            testID: 'copy-foster-action',
          },
          ...(currentOrganization?.adoptionContract?.url
            ? [
                {
                  icon: 'clipboard-flow',
                  label: 'Print Adoption Contract',
                  onPress: async () => {
                    await openFile({
                      fileId: currentOrganization?.adoptionContract?.id,
                      fileName: currentOrganization?.adoptionContract?.name,
                      mimeType: currentOrganization?.adoptionContract?.mimeType,
                    })
                  },
                  testID: 'print-adoption-contract-action',
                },
              ]
            : []),
          {
            icon: 'newspaper-variant',
            label: 'Print Cover Sheet',
            onPress: async () => {
              await pdfAction.openPDF()
            },
            testID: 'print-cover-sheet-action',
          },
          {
            ...(foster?.isAdopted
              ? {
                  icon: 'clipboard-minus',
                  label: 'Mark Returned',
                  onPress: () => {
                    setReturnAdoptionOpen(true)
                  },
                  testID: 'adoption-return-action',
                }
              : {
                  icon: 'clipboard-plus',
                  label: 'New Adoption Record',
                  onPress: () => {
                    setNewAdoptionRecordOpen(true)
                  },
                  testID: 'new-adoption-record-action',
                }),
          },

          {
            icon: 'scanner',
            label: 'Scan Documents',
            onPress: async () => {
              const uris = await scanFiles()
              console.log(uris)
              router.push(
                `/fosters/${fosterId}/documents?scanUris=${uris?.join(',')}`
              )
            },
            testID: 'scan-documents-action',
          },
        ]}
        icon={open ? 'close' : 'menu'}
        onStateChange={onStateChange}
        open={open}
        style={{
          position: 'absolute',
        }}
        testID="foster-action-button"
        visible={currentOrganization?.subscriptionActive === true}
      />
      <NewAdoptionAction
        onClose={() => setNewAdoptionRecordOpen(false)}
        open={newAdoptionRecordOpen}
      />
      <ReturnAdoptionAction
        onClose={() => setReturnAdoptionOpen(false)}
        open={returnAdoptionOpen}
      />
    </>
  )
}

export default FosterActionButton
