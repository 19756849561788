import { Link, router } from 'expo-router'
import { View } from 'react-native'
import { Button, DataTable } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

interface Props {
  data: {
    id: string
    name: string
    species: string
    minAgeWeeks: number | null
  }[]
  disabled: boolean
  editRoute: string
}

const OrganizationSettingTable = ({ data, disabled, editRoute }: Props) => {
  const { styles } = useStyles(stylesheet)

  return (
    <View>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={styles.nameColumn}>Name</DataTable.Title>
          <DataTable.Title style={styles.speciesColumn}>
            Species
          </DataTable.Title>
          <DataTable.Title style={styles.minAgeColumn}>Min Age</DataTable.Title>
        </DataTable.Header>
      </DataTable>
      {data?.map((d) => (
        <DataTable.Row
          disabled={disabled}
          key={d.id}
          onPress={() => {
            router.push(`${editRoute}/${d.id}`)
          }}
        >
          <DataTable.Cell style={styles.nameColumn}>{d.name}</DataTable.Cell>
          <DataTable.Cell style={styles.speciesColumn}>
            {d.species}
          </DataTable.Cell>
          <DataTable.Cell style={styles.minAgeColumn}>
            {d.minAgeWeeks}
          </DataTable.Cell>
        </DataTable.Row>
      ))}
      <Link asChild href={`${editRoute}/new`}>
        <Button disabled={disabled} mode="contained" style={styles.button}>
          Add
        </Button>
      </Link>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    button: {
      marginTop: theme.tokens.spacing[4],
      width: 120,
    },
    minAgeColumn: {
      flex: 0.4,
    },
    nameColumn: {
      flex: 1,
    },
    speciesColumn: {
      flex: 0.4,
    },
  }
})

export default OrganizationSettingTable
