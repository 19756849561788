import { useMemo, useState } from 'react'
import { getAccessToken } from '../utils/auth/auth'

const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useMemo(() => {
    if (!accessToken) {
      // Initial fetch
      getAccessToken().then((token) => {
        if (token) {
          setAccessToken(token)
        }
      })
    }

    // Set up interval to fetch every 5 minutes
    const interval = setInterval(
      () => {
        getAccessToken().then((token) => {
          if (token) {
            setAccessToken(token)
          }
        })
      },
      5 * 60 * 1000
    ) // 5 minutes in milliseconds

    // Cleanup interval on unmount
    return () => clearInterval(interval)
  }, [accessToken])

  return accessToken
}

export default useAccessToken
