import { RumActionType } from 'expo-datadog'
import { useNavigation } from 'expo-router'
import { useCallback, useEffect, useState } from 'react'
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  useWindowDimensions,
  View,
} from 'react-native'
import { RefreshControl } from 'react-native-gesture-handler'
import { AnimatedFAB } from 'react-native-paper'
import { FlatGrid } from 'react-native-super-grid'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterGridItem from '../../../../components/fosters/FosterGridItem'
import NewFoster from '../../../../components/fosters/NewFoster'
import FosterGridHeaderContent from '../../../../components/navigation/header/FosterGridHeaderContent'
import FosterService from '../../../../services/fosterService'
import { useAppStore } from '../../../../store/useAppStore'
import * as datadog from '../../../../utils/datadog/datadog'

const headerRight = () => <FosterGridHeaderContent />

const Grid = () => {
  const navigation = useNavigation()
  const { styles } = useStyles(stylesheet)
  const [isRefreshing, setRefreshing] = useState(false)
  const [isFABExtended, setIsFABExtended] = useState(true)
  const [isNewFosterSheetVisible, setIsNewFosterSheetVisible] = useState(false)

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const bulkSelect = useAppStore.use.bulkSelect()
  const setBulkSelect = useAppStore.use.setBulkSelect()
  const basicFilters = useAppStore.use.basicFilters()

  const { fetchNextPage, fosterList, refreshFosterList } =
    FosterService.useFosterList()

  useEffect(() => {
    navigation.setOptions({
      title: basicFilters.filters.viewAll ? 'All Fosters' : 'My Fosters',
      headerRight,
    })
  }, [navigation, basicFilters.filters.viewAll])

  const onRefresh = useCallback(async () => {
    setRefreshing(true)

    await refreshFosterList()

    setRefreshing(false)
  }, [refreshFosterList])

  const onScroll = ({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) => {
    const currentScrollPosition = Math.floor(nativeEvent?.contentOffset?.y) ?? 0

    setIsFABExtended(currentScrollPosition <= 500)
  }

  const onSelect = (id: string) => {
    // setBulkSelect({
    //   selection: {
    //     items: [],
    //   },
    // })
    if (bulkSelect.selection.items.includes(id)) {
      setBulkSelect({
        selection: {
          items: bulkSelect.selection.items.filter((i) => i !== id),
        },
      })
    } else {
      const newSelected = [...bulkSelect.selection.items, id]
      setBulkSelect({
        selection: {
          items: newSelected,
        },
      })
    }
  }

  const { width } = useWindowDimensions()

  let itemDimension = 180
  if (width < 400) {
    itemDimension = 145
  }

  const renderItem = useCallback(
    ({ item }: { item: (typeof fosterList)[number] }) => (
      <FosterGridItem
        foster={item}
        height={itemDimension + 20}
        isSelected={bulkSelect.selection.items.includes(item.id)}
        onSelect={onSelect}
      />
    ),
    [bulkSelect.selection.items, itemDimension, onSelect]
  )

  return (
    <View style={styles.root}>
      <FlatGrid
        contentInsetAdjustmentBehavior="automatic"
        data={fosterList}
        itemDimension={itemDimension}
        keyExtractor={(foster) => foster.id}
        onEndReached={() => {
          fetchNextPage()
        }}
        onEndReachedThreshold={0.5}
        onScroll={onScroll}
        onScrollBeginDrag={() => {
          datadog.startAction({
            type: RumActionType.SCROLL,
            name: 'fosterGrid',
          })
        }}
        onScrollEndDrag={() => {
          datadog.stopAction({
            type: RumActionType.SCROLL,
            name: 'fosterGrid',
          })
        }}
        refreshControl={
          <RefreshControl onRefresh={onRefresh} refreshing={isRefreshing} />
        }
        removeClippedSubviews={true}
        renderItem={renderItem}
        testID="foster-grid"
      />
      <AnimatedFAB
        animateFrom="right"
        disabled={!currentOrganization?.subscriptionActive}
        extended={isFABExtended}
        icon="plus"
        iconMode="dynamic"
        // This looks stupid, but it works around a bug on iOS where the label isn't spaced correctly. So we add a single whitespace character to the label.
        label={Platform.OS === 'ios' ? 'Add a Foster ' : 'Add a Foster'}
        onPress={() => setIsNewFosterSheetVisible(true)}
        style={styles.fabStyle}
      />
      <NewFoster
        onClose={() => setIsNewFosterSheetVisible(false)}
        open={isNewFosterSheetVisible}
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    fabStyle: {
      bottom: 32,
      position: 'absolute',
      right: 24,
    },
    root: {
      backgroundColor: theme.colors.background,
      flex: 1,
    },
  }
})

export default Grid
