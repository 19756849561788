import { AutoInstrumentationConfiguration } from '@datadog/mobile-react-native/lib/typescript/DdSdkReactNativeConfiguration'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { StatsigProviderExpo, useGateValue } from '@statsig/expo-bindings'
import { QueryClientProvider } from '@tanstack/react-query'
import { httpLink } from '@trpc/client'
import Constants from 'expo-constants'
import { DatadogProvider } from 'expo-datadog'
import * as Device from 'expo-device'
import * as Font from 'expo-font'
import * as NavigationBar from 'expo-navigation-bar'
import { Slot, usePathname } from 'expo-router'
import Head from 'expo-router/head'
import * as SplashScreen from 'expo-splash-screen'
import { StatusBar } from 'expo-status-bar'
import { useCallback, useEffect, useState } from 'react'
import {
  Appearance,
  LogBox,
  Platform,
  Text,
  useColorScheme,
  View,
} from 'react-native'
import { ClickOutsideProvider } from 'react-native-click-outside'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { isMaestro } from 'react-native-is-maestro'
import { KeyboardProvider } from 'react-native-keyboard-controller'
import { PaperProvider } from 'react-native-paper'
import { UnistylesRuntime } from 'react-native-unistyles'
import superjson from 'superjson'
import z from 'zod'
import { ApiRoot, Feature } from '../../constants'
import SnackbarComponent from '../components/snackbar/SnackbarComponent'
import useQueryClient from '../hooks/useQueryClient'
import { useAppStore } from '../store/useAppStore'
import { darkTheme, lightTheme } from '../styles/theme'
import appStoreUpdateCheck from '../utils/appStoreUpdateCheck'
import { doesSessionExist } from '../utils/auth/auth'
import * as datadog from '../utils/datadog/datadog'
import { ddConfig } from '../utils/datadog/datadog.native'
import trpc from '../utils/trpc'
import zodErrorMap from '../utils/zodErrorMap'

if (isMaestro()) {
  LogBox.ignoreAllLogs()
}

SplashScreen.preventAutoHideAsync()

appStoreUpdateCheck()

z.setErrorMap(zodErrorMap)

const Root = () => {
  const [appIsReady, setAppIsReady] = useState(false)
  const colorScheme = useColorScheme()
  const auth = useAppStore.use.auth()
  const setAuth = useAppStore.use.setAuth()
  const [theme, setTheme] = useState(
    colorScheme === 'dark' ? darkTheme : lightTheme
  )
  const hasDatadogRUM = useGateValue(Feature.DatadogRUM)
  const datadogConfig = ddConfig(hasDatadogRUM)

  // Enables edge-to-edge on Android
  if (Platform.OS === 'android') {
    NavigationBar.setPositionAsync('absolute')
    NavigationBar.setBackgroundColorAsync('rgba(0, 0, 0, 0)')
  }

  Appearance.addChangeListener(({ colorScheme: scheme }) => {
    setTheme(scheme === 'dark' ? darkTheme : lightTheme)
    // Unistyles adaptive themes are supposed to handle this transition automatically.
    // But something in our app is conflicting with it, so we need to set it here as we're doing for Paper as well
    UnistylesRuntime.setTheme(scheme === 'dark' ? 'dark' : 'light')
  })

  const queryClient = useQueryClient()
  const [trpcClient] = useState(() =>
    trpc.createClient({
      // @ts-expect-error - we need to fix this
      transformer: superjson,
      links: [
        httpLink({
          url: `${ApiRoot()}/trpc`,
        }),
      ],
    })
  )

  useEffect(() => {
    doesSessionExist().then((exists) => {
      setAuth({
        ...auth,
        isLoggedIn: exists,
      })
    })

    async function prepare() {
      try {
        await Font.loadAsync({
          // Example font, uncomment to use
          // Inter: require('@tamagui/font-inter/otf/Inter-Medium.otf'),
        })

        // Until https://github.com/expo/router/issues/837 is fixed let's give a bit of time to the renderer to get the drawer rendered and hidden
        await new Promise((resolve) => setTimeout(resolve, 50))
      } catch (e) {
        console.warn(e)
      } finally {
        setAppIsReady(true)
      }
    }

    prepare()
  }, [setAuth])

  const onLayoutView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync()
    }
  }, [appIsReady])

  if (!appIsReady) {
    return null
  }

  // let tanstackDev
  // if (__DEV__ && Platform.OS === 'web') {
  //   tanstackDev = require('@tanstack/react-query-devtools')
  // }

  const root = (
    <>
      <Head>
        <title>Rescuebase</title>
        <meta content="true" property="expo:handoff" />
        <meta content="true" property="expo:spotlight" />
      </Head>
      <StatsigProviderExpo
        loadingComponent={<Text>Loading...</Text>}
        options={{
          environment: {
            tier: process.env.EXPO_PUBLIC_CHANNEL || process.env.NODE_ENV,
          },
        }}
        sdkKey={process.env.EXPO_PUBLIC_STATSIG_SDK_KEY || ''}
        user={{
          appVersion: Constants.expoConfig?.version,
          custom: {
            e2eTest: process.env.EXPO_PUBLIC_E2E_TEST === 'true' || isMaestro(),
            platform: Platform.OS,
            brand: Device.brand || 'unknown',
            designName: Device.designName || 'unknown',
            deviceName: Device.deviceName || 'unknown',
            deviceType: Device.deviceType || 'unknown',
            manufacturer: Device.manufacturer || 'unknown',
            model: Device.modelName || 'unknown',
            modelId: Device.modelId || 'unknown',
            modelName: Device.modelName || 'unknown',
            osName: Device.osName || 'unknown',
            osVersion: Device.osVersion || 'unknown',
            platformApiLevel: Device.platformApiLevel || 'unknown',
            productName: Device.productName || 'unknown',
            supportedCpuArchitectures:
              Device.supportedCpuArchitectures || 'unknown',
          },
        }}
      >
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <PaperProvider theme={theme}>
              <GestureHandlerRootView style={{ flex: 1 }}>
                <KeyboardProvider
                  navigationBarTranslucent={true}
                  statusBarTranslucent={true}
                >
                  <View
                    onLayout={onLayoutView}
                    style={{
                      flex: 1,
                      backgroundColor: theme.colors.background,
                    }}
                  >
                    <BottomSheetModalProvider>
                      <ActionSheetProvider>
                        <ClickOutsideProvider>
                          <PageViewTracker />
                          <Slot key="root-slot" />
                        </ClickOutsideProvider>
                      </ActionSheetProvider>
                    </BottomSheetModalProvider>
                  </View>
                  <StatusBar style="auto" />
                  {/* {tanstackDev && (
                    <tanstackDev.ReactQueryDevtools initialIsOpen={false} />
                  )} */}
                </KeyboardProvider>
              </GestureHandlerRootView>
              <SnackbarComponent />
            </PaperProvider>
          </QueryClientProvider>
        </trpc.Provider>
      </StatsigProviderExpo>
    </>
  )

  if (Platform.OS === 'web') {
    return root
  } else {
    return (
      <DatadogProvider
        configuration={datadogConfig as AutoInstrumentationConfiguration}
      >
        {root}
      </DatadogProvider>
    )
  }
}

// Keep this one private. It's only used internally by the Root component,
// and it's encapsulated to avoid re-rendering the entire root component on every page change
const PageViewTracker = () => {
  const pathname = usePathname()

  // remove replace uuids in viewKey with a ?
  const uuidRegex =
    /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g
  const pathnameGrouped = pathname.replace(uuidRegex, '?')

  datadog.trackPageView(pathname, pathnameGrouped)

  return null
}

export default Root
