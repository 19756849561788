import { zodResolver } from '@hookform/resolvers/zod'
import { useLocalSearchParams, useNavigation } from 'expo-router'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import FormFieldCheckbox from '../../../components/form/FormFieldCheckbox'
import FormFieldDate from '../../../components/form/FormFieldDate'
import FormFieldSelect from '../../../components/form/FormFieldSelect'
import FormFieldText from '../../../components/form/FormFieldText'
import FormFieldVolunteer from '../../../components/fosters/cards/formFields/FormFieldVolunteer'
import FormFieldWeight from '../../../components/fosters/cards/formFields/FormFieldWeight'
import FosterMedicalForm from '../../../components/fosters/cards/FosterMedicalForm'
import fosterSharedStyles from '../../../components/fosters/cards/fosterSharedStyles'
import FosterThumbnail from '../../../components/fosters/FosterThumbnail'
import Card from '../../../components/shared/Card'
import Page from '../../../components/shared/Page'
import Species from '../../../enums/species'
import FosterService from '../../../services/fosterService'
import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'

const fosterBulkFormSchema = z.object({
  birthDate: z.date().optional(),
  breed: z.string().optional(),
  clearFecalTestDate: z.date().optional(),
  comboTestingCompletedDate: z.date().optional(),
  currentFood: z.string().optional(),
  fecalFollowUpDate: z.date().optional(),
  felvPositive: z.boolean().optional(),
  fivPositive: z.boolean().optional(),
  fixedBeforeIntake: z.boolean().optional(),
  fixedOnDate: z.date().optional(),
  gender: z.enum(['Male', 'Female']).optional(),
  heartwormPositive: z.boolean().optional(),
  heartwormTestingCompletedDate: z.date().optional(),
  history: z.string().optional(),
  intakeDate: z.date().optional(),
  isAdopted: z.boolean().optional(),
  nextComboMedDue: z.date().optional(),
  nextFleaTickMedDue: z.date().optional(),
  nextHeartwormPreventionDue: z.date().optional(),
  notes: z.string().optional(),
  positiveFecalTestDate: z.date().optional(),
  readyForAdoption: z.boolean().optional(),
  species: z.nativeEnum(Species).optional(),
  userId: z.string().optional(),
  weight: z.string().optional(),
})

export type FosterBulkForm = z.infer<typeof fosterBulkFormSchema>

const SaveButton = ({
  isLoading,
  onSubmit,
}: {
  isLoading: boolean
  onSubmit: () => void
}) => (
  <Button
    loading={isLoading}
    onPress={() => {
      onSubmit()
    }}
  >
    Save
  </Button>
)

const FosterBulkEdit = () => {
  const navigation = useNavigation()

  const { styles } = useStyles(stylesheet)
  const { styles: sharedStyles } = useStyles(fosterSharedStyles)
  const bulkUpdateMutation = trpc.foster.bulkUpdate.useMutation()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const { ids } = useLocalSearchParams()
  const idArray = (ids as string).split(',')

  const { fosterList } = FosterService.useFosterList()
  const speciesSet = new Set(
    idArray.map((id) => fosterList.find((f) => f.id === id)?.species)
  )
  const species =
    speciesSet.size === 1 ? speciesSet.values().next().value : undefined

  const { control, formState, handleSubmit, setValue } =
    useForm<FosterBulkForm>({
      mode: 'onBlur',
      resolver: zodResolver(fosterBulkFormSchema),
      defaultValues: {
        species: species as Species,
      },
    })

  const submit = handleSubmit((data) => {
    bulkUpdateMutation.mutate({
      ids: idArray,
      ...data,
    })
  })

  useEffect(() => {
    navigation.setOptions({
      title: `Editing ${idArray.length} fosters`,
      headerRight: () =>
        SaveButton({
          isLoading: bulkUpdateMutation.isLoading,
          onSubmit: () => submit(),
        }),
    })
  }, [idArray.length, navigation, submit, bulkUpdateMutation.isLoading])

  return (
    <Page viewId="foster-detail-scroll-view">
      <View style={styles.container}>
        <Card title={`Editing ${idArray.length} fosters`}>
          <View style={styles.fosterThumbnailContainer}>
            {idArray.map((id) => {
              const foster = fosterList.find((f) => f.id === id)
              return (
                <View key={id}>
                  <View style={styles.fosterThumbnail}>
                    <FosterThumbnail
                      blurhash={foster?.blurhash}
                      species={foster?.species as Species}
                      style={[StyleSheet.absoluteFill, styles.fosterThumbnail]}
                      thumbUri={foster?.thumbUri}
                    />
                  </View>
                  <Text
                    style={styles.fosterThumbnailName}
                    variant="titleMedium"
                  >
                    {foster?.name}
                  </Text>
                </View>
              )
            })}
          </View>
        </Card>
      </View>
      <View style={styles.container}>
        <Card title="Foster Details">
          <View style={sharedStyles.textInputContainer}>
            <FormFieldSelect
              control={control}
              data={[
                { label: 'Dog', value: Species.Dog },
                { label: 'Cat', value: Species.Cat },
              ]}
              disable={!currentOrganization?.subscriptionActive}
              errors={formState.errors}
              fieldName="species"
              label="Species"
              required={true}
              style={[styles.fosterDetailInput]}
            />
            <FormFieldVolunteer
              control={control}
              disabled={!currentOrganization?.subscriptionActive}
              formState={formState}
              isLoading={false}
              style={[styles.fosterDetailInput, styles.fullWidthFlexBasis]}
            />
            <FormFieldText
              control={control}
              disabled={!currentOrganization?.subscriptionActive}
              errors={formState.errors}
              fieldName="breed"
              label="Breed"
              required={true}
              style={[styles.fosterDetailInput, styles.fullWidthFlexBasis]}
            />
            <FormFieldDate
              control={control}
              disabled={!currentOrganization?.subscriptionActive}
              errors={formState.errors}
              fieldName="birthDate"
              label="Birth Date"
              required={true}
              style={[styles.fosterDetailInput]}
            />
            <FormFieldDate
              control={control}
              disabled={!currentOrganization?.subscriptionActive}
              errors={formState.errors}
              fieldName="intakeDate"
              label="Intake Date"
              required={true}
              style={[styles.fosterDetailInput]}
            />
            <FormFieldText
              control={control}
              disabled={!currentOrganization?.subscriptionActive}
              errors={formState.errors}
              fieldName="currentFood"
              label="Current Food"
              required={true}
              style={[styles.fosterDetailInput, styles.fullWidthFlexBasis]}
            />
            <FormFieldWeight
              control={control}
              disabled={!currentOrganization?.subscriptionActive}
              errors={formState.errors}
              fieldName="weight"
              style={[styles.fosterDetailInput]}
            />
            <FormFieldText
              contentStyle={styles.notes}
              control={control}
              disabled={!currentOrganization?.subscriptionActive}
              errors={formState.errors}
              fieldName="notes"
              helperText="Notes entered here will be added to each foster's notes."
              label="Notes"
              multiline
              required={false}
              style={[styles.fosterDetailInput, styles.fullWidthFlexBasis]}
            />
          </View>
        </Card>
      </View>
      <View style={styles.container}>
        <FosterMedicalForm
          control={control}
          disabled={!currentOrganization?.subscriptionActive}
          formState={formState}
          isLoading={false}
          setValue={setValue}
          species={species as Species}
          submit={() => {}}
        />
      </View>
      <View style={styles.container}>
        <Card title="Adoption Info">
          <FormFieldCheckbox
            control={control}
            disabled={!currentOrganization?.subscriptionActive}
            fieldName="readyForAdoption"
            formState={formState}
            label="Ready for Adoption"
            required={false}
            status="checked"
          />
          <FormFieldCheckbox
            control={control}
            disabled={!currentOrganization?.subscriptionActive}
            fieldName="isAdopted"
            formState={formState}
            label="Adopted"
            required={false}
            status="checked"
          />
        </Card>
      </View>
    </Page>
  )
}

export default FosterBulkEdit

const stylesheet = createStyleSheet((theme) => {
  return {
    container: {
      columnGap: theme.tokens.spacing[4],
      flexDirection: 'column',
      rowGap: theme.tokens.spacing[4],
    },
    fosterDetailInput: {
      flex: 1,
      flexBasis: '45%',
      flexGrow: 1,
    },
    fosterThumbnail: {
      borderRadius: theme.tokens.containerBorderRadius,
      height: 80,
      width: 80,
    },
    fosterThumbnailContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.tokens.spacing[4],
      justifyContent: 'flex-start',
    },
    fosterThumbnailName: {
      textAlign: 'center',
    },
    fullWidthFlexBasis: {
      flexBasis: {
        xs: '100%',
        md: '30%',
      },
    },
    notes: {
      minHeight: 150,
    },
  }
})
