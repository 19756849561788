import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import { Stack } from 'expo-router'
import { useStyles } from 'react-native-unistyles'
import FosterHeader from '../../../../components/navigation/header/FosterHeader'

export const unstable_settings = {
  initialRouteName: '(fosters)/index',
}

const HeaderComponent = (props: NativeStackHeaderProps) => {
  return <FosterHeader {...props} />
}

const FosterRootLayout = () => {
  const { theme } = useStyles()

  return (
    <Stack
      screenOptions={{
        contentStyle: { backgroundColor: theme.colors.background },
        header: HeaderComponent,
        headerBackVisible: true,
        freezeOnBlur: false,
      }}
    />
  )
}

export default FosterRootLayout
