import { ErrorCode, Purchases, PurchasesError } from '@revenuecat/purchases-js'

async function configure(userId: string) {
  Purchases.configure(
    process.env.EXPO_PUBLIC_REVENUE_CAT_WEB_API_KEY || '',
    userId
  )
}

async function getCustomerInfo() {
  if (!Purchases.isConfigured()) {
    return
  }

  return Purchases.getSharedInstance().getCustomerInfo()
}

async function isConfigured() {
  return Purchases.isConfigured()
}

async function logIn(userId: string) {
  return Purchases.getSharedInstance().changeUser(userId)
}

async function showPaywall() {
  try {
    const offerings = await Purchases.getSharedInstance().getOfferings()

    if (!offerings.current) {
      return
    }

    const { customerInfo } = await Purchases.getSharedInstance().purchase({
      rcPackage: offerings.current?.availablePackages[0],
    })

    if (Object.keys(customerInfo.entitlements.active).includes('pro')) {
      // Unlock that great "pro" content
    }
  } catch (e) {
    if (
      e instanceof PurchasesError &&
      e.errorCode == ErrorCode.UserCancelledError
    ) {
      // User cancelled the purchase process, don't do anything
    } else {
      // Handle errors
    }
  }
}

export default {
  configure,
  getCustomerInfo,
  isConfigured,
  logIn,
  showPaywall,
}
